import { styled } from '@mui/material/styles';
import { MaterialDesignContent } from 'notistack';

const StyledSnackbar = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent-default': {
        padding: 0,
        minWidth: '288px',
        '#notistack-snackbar': {
            padding: 0,
            minWidth: '288px',
            width: '100%',
        },
    },
    '&.notistack-MuiContent-info': {
        padding: 0,
        minWidth: '288px',
        '#notistack-snackbar': {
            padding: 0,
            minWidth: '288px',
            width: '100%',
        },
    },
    '&.notistack-MuiContent-success': {
        padding: 0,
        minWidth: '288px',
        '#notistack-snackbar': {
            padding: 0,
            minWidth: '288px',
            width: '100%',
        },
    },
    '&.notistack-MuiContent-warning': {
        padding: 0,
        minWidth: '288px',
        '#notistack-snackbar': {
            padding: 0,
            minWidth: '288px',
            width: '100%',
        },
    },
    '&.notistack-MuiContent-error': {
        padding: 0,
        minWidth: '288px',
        '#notistack-snackbar': {
            padding: 0,
            minWidth: '288px',
            width: '100%',
        },
    },
}));

export const snackbarConfig = {
    variant: 'error',
    preventDuplicate: true,
    autoHideDuration: 5000,
    hideIconVariant: true,
    anchorOrigin: {
        horizontal: 'left',
        vertical: 'bottom',
    },
    dense: true,
    Components: {
        default: StyledSnackbar,
        success: StyledSnackbar,
        info: StyledSnackbar,
        warning: StyledSnackbar,
        error: StyledSnackbar,
    },
};
