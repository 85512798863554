import { useState } from 'react';

import {
    Box,
    Typography,
    Card,
    CardContent,
    Link,
    Collapse,
    useMediaQuery,
} from '@mui/material';
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import {
    KeyboardArrowRight as KeyboardArrowRightIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';

import { Link as RouterLink, useLocation } from 'react-router-dom';

import { borderWithTransition } from 'config/customCSS.js';
import cateLv1Json from 'data/cateLv1.json';
import cateLv2Json from 'data/cateLv2.json';
import cateLv3Json from 'data/cateLv3.json';
import cateImageJson from 'data/cateImage.json';

import { CustomButton, CustomMasonry, CustomAvatar } from 'UI';

const CATEGORIES = [...cateLv1Json, ...cateLv2Json, ...cateLv3Json];

const customTheme = (outerTheme) =>
    createTheme({
        components: {
            MuiTypography: {
                styleOverrides: {
                    noWrap(styles) {
                        return {
                            whiteSpace: 'initial',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: String(
                                styles.ownerState['data-lines'] || '3'
                            ),
                            WebkitBoxOrient: 'vertical',
                        };
                    },
                },
            },
        },
    });

function SubcateCard({ id = '', name = '', slug = '', ...otherProps }) {
    const theme = useTheme();
    const location = useLocation().pathname;
    const mainSlug = location.split('/')[1];

    return (
        <Card
            variant="outlined"
            key={id + '-card-subCate'}
            sx={{
                backgroundImage:
                    'linear-gradient(to top, rgba(0, 0, 0, 0.05),white, white, white)',
                backgroundColor: 'white',
                ...borderWithTransition,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                borderRadius: '12px',
                border: '1px solid #EDEDED',
                mb: 0.5,
            }}>
            <Link
                component={RouterLink}
                to={'/' + mainSlug + slug}
                underline="none">
                <CustomAvatar
                    styleProps={{ mx: 'auto', p: 1 }}
                    variant="square"
                    src={cateImageJson[slug]}
                    size={120}
                    alt={slug}
                />
            </Link>
            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    p: 0,
                    pb: '0px !important',
                }}>
                <Link
                    component={RouterLink}
                    to={'/' + mainSlug + slug}
                    underline="none">
                    <ThemeProvider theme={customTheme(theme)}>
                        <Typography
                            noWrap
                            component="h3"
                            data-lines={'2'}
                            sx={{
                                color: 'darkblue',
                                fontSize: 14,
                                fontWeight: 600,
                                textTransform: 'initial',
                                textAlign: 'center',
                                height: 44,
                                px: 1,
                            }}>
                            {name}
                        </Typography>
                    </ThemeProvider>
                </Link>
            </CardContent>
        </Card>
    );
}

export default function CategorySection({ ...otherProps }) {
    const pathnames = useLocation()
        .pathname.split('/')
        .filter((i) => i)
        .map((i) => '/' + i);
    const currentLevel = pathnames.length;

    const theme = useTheme();
    const biggerSM = useMediaQuery(theme.breakpoints.up('sm')); // >=600
    const [showCollapse, setShowCollapse] = useState({
        1: false,
        2: false,
        3: false,
    });

    const getParentCate = (mainPath, subPath) => {
        if (mainPath === '/thuoc') {
            if (currentLevel === 1) {
                return CATEGORIES.filter(
                    (i) => i.id.startsWith('01') && i.level === 2
                );
            }
            if (currentLevel === 2 && subPath) {
                return CATEGORIES.filter(
                    (i) => i.slug === subPath && i.level === 2
                );
            }
        }
        if (mainPath === '/san-pham-khac') {
            if (currentLevel === 1) {
                return CATEGORIES.filter(
                    (i) => i.id.startsWith('03') && i.level === 2
                );
            }
            if (currentLevel === 2 && subPath) {
                return CATEGORIES.filter(
                    (i) => i.slug === subPath && i.level === 2
                );
            }
        }
        if (mainPath === '/thuc-pham-chuc-nang') {
            if (currentLevel === 1) {
                return CATEGORIES.filter(
                    (i) => i.id.startsWith('02') && i.level === 1
                );
            }
        }
        return [];
    };

    let parentCategories = getParentCate(
        pathnames?.[0] ?? null,
        pathnames?.[1] ?? null
    );

    if (parentCategories.length === 0) {
        return null;
    }

    return parentCategories.map((parentCate, index) => {
        let title =
            'Danh mục: ' +
            parentCate.name +
            ' (' +
            CATEGORIES.filter((i) => i.parentName === parentCate.name).length +
            ')';
        return (
            <Box key={parentCate.name} sx={{ width: '100%' }}>
                <CustomButton
                    id="category-section-expand-button"
                    disableEffect
                    onClick={() => {
                        setShowCollapse((prev) => {
                            let updatedValue = { ...prev };
                            updatedValue[index] = !updatedValue[index];
                            return updatedValue;
                        });
                    }}
                    startIcon={
                        showCollapse[index] ? (
                            <KeyboardArrowDownIcon />
                        ) : (
                            <KeyboardArrowRightIcon />
                        )
                    }>
                    <Typography variant="subtitle2" sx={{ fontSize: 14 }}>
                        {title}
                    </Typography>
                </CustomButton>
                <Collapse in={showCollapse[index]} timeout="auto" unmountOnExit>
                    <CustomMasonry
                        spacing={biggerSM ? 1 : 0.75}
                        elevation={1}
                        id={`${parentCate.name}-custom-masonry`}
                        minHeight={100}
                        columns={{ xs: 3, md: 4, lg: 5, xl: 6 }}
                        items={CATEGORIES.filter(
                            (i) => i.parentName === parentCate.name
                        ).map(({ id, name, slug }) => {
                            return {
                                id,
                                content: (
                                    <SubcateCard
                                        id={id}
                                        name={name}
                                        slug={slug}
                                    />
                                ),
                            };
                        })}
                        wrap={false}
                    />
                </Collapse>
            </Box>
        );
    });
}
