import { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
    FormControl,
    FormLabel,
    FormGroup,
    FormControlLabel,
    FormHelperText,
    Switch,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { lighten } from '@mui/system';

import useCustomColor from 'hooks/useCustomColor';

const StyledSwitch = styled(Switch)(({ theme, ...props }) => {
    const { color } = props;
    let { main, light } = useCustomColor(color);

    return {
        '& .MuiSwitch-switchBase': {
            '&.Mui-checked': {
                color: main,
                '& + .MuiSwitch-track': {
                    backgroundColor: light,
                },
                '&.Mui-disabled': {
                    color: lighten(main, 0.5),
                },
            },
            ':not(.Mui-checked)': {
                color: lighten('#fff', 0.1),
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette['disabled'].main,
                },
                '&.Mui-disabled': {
                    color: theme.palette['disabled'].lighter,
                },
            },
        },
    };
});
/**
 * CustomSwitch component renders a set of switch options with support for custom styling, labels, and helper text.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.id - The unique identifier for the form control.
 * @param {Array} props.options - The list of options to display as switches.
 * @param {Function} props.formatOption - Function to format each option, returning an object with `name`, `label`, and `checked`.
 * @param {Function} props.getOptionDisabled - Function to determine if an option should be disabled.
 * @param {Function} props.onChange - Callback function when an option's state changes.
 * @param {string} props.label - The form label displayed above the switches.
 * @param {string} props.helperText - Helper text displayed below the switches.
 * @param {string} props.error - Error message displayed below the switches.
 * @param {string} props.color - Color for the switches.
 * @param {string} props.size - Size of the switches, either 'small' or 'medium'.
 * @param {string} props.margin - Margin setting, either 'none' or 'dense'.
 * @param {string} props.labelPlacement - Position of the label relative to the switch.
 * @param {boolean} props.disabled - If true, all switches will be disabled.
 * @param {boolean} props.required - If true, displays an asterisk to indicate required fields.
 * @param {boolean} props.row - If true, displays switches in a row.
 * @param {Object} props.styleProps - Custom styles to apply to each switch.
 * @param {Object} props.FormControlProps - Additional props for the FormControl component.
 * @param {Object} props.FormLabelProps - Additional props for the FormLabel component.
 * @param {Object} props.FormGroupProps - Additional props for the FormGroup component.
 * @param {Object} props.FormHelperTextProps - Additional props for the FormHelperText component.
 * @returns {JSX.Element} Rendered component.
 */
function CustomSwitch({
    id,
    options = [],
    formatOption = (opt) => ({
        name: opt,
        label: opt,
        checked: true,
    }),
    getOptionDisabled = (option) => false,
    onChange = () => {},
    label: formLabel = '',
    helperText = '',
    error = '',
    color = 'primary',
    size = 'small',
    margin = 'none',
    labelPlacement = 'end',
    disabled: formDisabled = false,
    required = false,
    row = false,
    styleProps = {},
    FormControlProps,
    FormLabelProps,
    FormGroupProps,
    FormHelperTextProps,
    ...otherProps
}) {
    const handleChange = (event) => {
        let name = event.target.name;
        let checked = event.target.checked;
        onChange({ name, checked });
    };
    let defaultFormControlProps = {
        component: 'fieldset',
        variant: 'standard',
        disabled: formDisabled,
        required,
        margin,
        color,
        error: Boolean(error),
    };
    let formLabelProps = {
        component: 'legend',
        margin,
        ...FormLabelProps,
    };
    let switchProps = {
        size,
        color,
        margin,
        onChange: handleChange,
        labelPlacement,
    };
    const formId = useMemo(
        () => id || Math.random().toString(36).slice(2, 11),
        [id]
    );

    return (
        <FormControl {...defaultFormControlProps} {...FormControlProps}>
            <FormLabel {...formLabelProps}>{formLabel}</FormLabel>
            {options.length > 0 && (
                <FormGroup row={row} {...FormGroupProps}>
                    {options.map((opt, idx, array) => {
                        const { name, label, checked } = formatOption(opt);

                        return (
                            <FormControlLabel
                                key={`${formId}-${idx + 1}`}
                                disabled={
                                    formDisabled || getOptionDisabled(opt)
                                }
                                labelPlacement={labelPlacement}
                                sx={{
                                    margin: margin === 'dense' ? '4px 0' : 0,
                                    ...styleProps,
                                }}
                                control={
                                    <StyledSwitch
                                        name={name}
                                        checked={checked}
                                        {...switchProps}
                                    />
                                }
                                label={label}
                            />
                        );
                    })}
                </FormGroup>
            )}
            {(error || helperText) && (
                <FormHelperText
                    id={`${formId}-helper-text`}
                    {...FormHelperTextProps}>
                    {error || helperText}
                </FormHelperText>
            )}
        </FormControl>
    );
}

CustomSwitch.propTypes = {
    id: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.any).isRequired,
    formatOption: PropTypes.func.isRequired,
    getOptionDisabled: PropTypes.func,
    onChange: PropTypes.func,
    label: PropTypes.string,
    helperText: PropTypes.string,
    error: PropTypes.string,
    color: PropTypes.oneOfType([
        PropTypes.oneOf([
            'primary',
            'secondary',
            'warning',
            'error',
            'info',
            'success',
            'disabled',
        ]),
        PropTypes.string,
    ]),
    size: PropTypes.oneOf(['small', 'medium']),
    margin: PropTypes.oneOf(['none', 'dense']),
    labelPlacement: PropTypes.oneOf(['end', 'start', 'bottom', 'top']),
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    row: PropTypes.bool,
    styleProps: PropTypes.object,
    FormControlProps: PropTypes.object,
    FormLabelProps: PropTypes.object,
    FormGroupProps: PropTypes.object,
    FormHelperTextProps: PropTypes.object,
};

CustomSwitch.defaultProps = {
    options: [],
    formatOption: (opt) => ({
        name: opt,
        label: opt,
        checked: true,
    }),
    getOptionDisabled: () => false,
    onChange: () => {},
    label: '',
    helperText: '',
    error: '',
    color: 'primary',
    size: 'small',
    margin: 'none',
    labelPlacement: 'end',
    disabled: false,
    required: false,
    row: false,
    styleProps: {},
};

export default CustomSwitch;
