import { ToggleButtonGroup, ToggleButton } from '@mui/material';

import { getColorRGBA } from 'utils/getColorRGBA.js';

function getStyles({ variant = 'outlined', color = 'disabled' }) {
    let chosenColor = getColorRGBA(color);
    let lighterChosenColor = getColorRGBA(color, 0.4);

    let css = {
        text: {
            color: 'rgb(0, 0, 0, 0.9)',
            backgroundColor: 'transparent',
            borderRadius: 0,
            border: 'none',
            '&.Mui-selected': {
                color: chosenColor,
                borderRadius: 0,
                border: 'none',
                backgroundColor: 'transparent',
                ':hover': {
                    backgroundColor: lighterChosenColor,
                },
                '&:active': {
                    backgroundColor: lighterChosenColor,
                },
                '&:focus': {
                    backgroundColor: lighterChosenColor,
                },
            },
            '&.Mui-disabled': {
                color: '#EDEDED',
                backgroundColor: 'transparent',
                border: 'none',
            },
        },
        outlined: {
            color: 'rgb(0, 0, 0, 0.9)',
            backgroundColor: 'transparent',
            '&.Mui-selected': {
                color: chosenColor,
                backgroundColor: 'transparent',
                ':hover': {
                    backgroundColor: lighterChosenColor,
                },
                '&:active': {
                    backgroundColor: lighterChosenColor,
                },
                '&:focus': {
                    backgroundColor: lighterChosenColor,
                },
            },
            '&.Mui-disabled': {
                color: 'lightgrey',
                backgroundColor: '#EDEDED',
            },
        },
        contained: {
            color: 'rgb(0, 0, 0, 0.9)',
            backgroundColor: '#EDEDED',
            '&.Mui-selected': {
                color: chosenColor,
                backgroundColor: lighterChosenColor,
                ':hover': {
                    backgroundColor: lighterChosenColor,
                },
                '&:active': {
                    backgroundColor: lighterChosenColor,
                },
                '&:focus': {
                    backgroundColor: lighterChosenColor,
                },
            },
            '&.Mui-disabled': {
                color: 'lightgrey',
                backgroundColor: '#EDEDED',
            },
        },
    };
    return css[variant];
}

export default function CustomToggleButton({
    id, // required
    options = [
        // {
        //     value: 'option1',
        //     icon: <Material-icon />,
        //     disabled: true/false,
        //     color: 'primary' // accepted: default, error, warning, info, success
        // },
    ], // required
    value = '', // required, accept '' if exclusive and an array of value if !exclusive
    onChange = () => {}, // required
    exclusive = false,
    disabled: groupDisabled = false,
    fullWidth = false,
    orientation = 'horizontal', // accepted: horizontal, vertical
    size = 'medium', // small, medium , large
    variant = 'outlined', // accepted: outlined, text, contained
    ...otherProps
}) {
    const handleToggle = (event, newData) => {
        onChange(newData);
    };
    return (
        <ToggleButtonGroup
            id={id}
            value={value}
            exclusive={exclusive}
            disabled={groupDisabled}
            fullWidth={fullWidth}
            orientation={orientation}
            size={size}
            onChange={handleToggle}
            aria-label={`toggle-button${id ? '-' + id : ''}`}
            {...otherProps}>
            {options.map((opt) => {
                let {
                    color = 'default',
                    value,
                    disabled: buttonDisabled = false,
                    icon,
                } = opt;
                const styles = getStyles({
                    variant,
                    color,
                });
                return (
                    <ToggleButton
                        sx={styles}
                        key={value}
                        value={value}
                        disabled={groupDisabled || buttonDisabled}
                        aria-label={'toggle-button-' + value}>
                        {icon}
                    </ToggleButton>
                );
            })}
        </ToggleButtonGroup>
    );
}
