import { Typography } from '@mui/material';

export default function PageTitle({
    totalArticles = 0,
    cateTitle = '',
    ...otherProps
}) {
    return (
        <Typography component="h1" sx={{ fontSize: 18, fontWeight: 600 }}>
            {`Danh mục: ${cateTitle.toLowerCase()}`}
        </Typography>
    );
}
