import { Stack, Box, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Inventory2 as Inventory2Icon } from '@mui/icons-material';

import { useQuery } from '@tanstack/react-query';
import { searchData } from 'utils/http.js';

import { underlineWithTransition } from 'config/customCSS.js';

import { CustomMasonry } from 'UI';
import ArticleCard from '../articles/ArticleCard.jsx';

export default function SimilarArticle({
    keywords = [],
    currentSlug = '',
    ...otherProps
}) {
    const theme = useTheme();
    const biggerSM = useMediaQuery(theme.breakpoints.up('sm')); // >=600
    const biggerMD = useMediaQuery(theme.breakpoints.up('md')); // >=800
    const { data } = useQuery({
        queryKey: ['article-item', { keywordIds: keywords }],
        queryFn: ({ signal }) =>
            searchData({
                signal,
                apiUrl: `/article-item?keywordIds=${keywords.join(
                    ','
                )}&type=1&skip=0&take=20`,
            }),
        staleTime: 30000,
        retry: 0,
        enabled: keywords.length > 0,
    });

    if (
        keywords.length === 0 ||
        !(data && data.data.filter((i) => i.slug !== currentSlug).length > 0)
    ) {
        return null;
    }
    return (
        <Box>
            <Stack pl={2} direction="row" alignItems="center" spacing={1}>
                <Inventory2Icon
                    fontSize="small"
                    sx={{
                        color: 'darkblue',
                    }}
                />
                <Typography
                    variant="h6"
                    sx={{ color: 'darkblue', ...underlineWithTransition }}>
                    Bài viết tương tự
                </Typography>
            </Stack>
            <CustomMasonry
                spacing={biggerSM ? 2 : 0.75}
                elevation={1}
                id="similar-article-custom-masonry"
                columns={{ xs: 1, md: 2, xl: 3 }}
                items={data.data
                    .filter((i) => i.slug !== currentSlug)
                    .map((articleData) => {
                        return {
                            id: articleData._id,
                            content: (
                                <ArticleCard
                                    articleData={articleData}
                                    viewType={
                                        biggerMD ? 'vertical' : 'horizontal'
                                    }
                                />
                            ),
                        };
                    })}
                wrap={false}
            />
        </Box>
    );
}
