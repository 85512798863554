import { Stack, Typography } from '@mui/material';
import { CustomCircularProgress } from 'UI';

export default function LoadingFallback({ ...props }) {
    return (
        <Stack
            height="100vh"
            width="100vw"
            spacing={2}
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ backgroundColor: '#EDEDED' }}>
            <CustomCircularProgress />
            <Typography sx={{ color: 'primary.main' }}>Đang tải...</Typography>
        </Stack>
    );
}
