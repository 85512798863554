import { Stack, Typography, Box } from '@mui/material';
import {
    ListAlt as ListAltIcon,
    GridViewOutlined as GridViewOutlinedIcon,
    GridViewSharp as GridViewSharpIcon,
    FilterListOutlined as FilterListOutlinedIcon,
} from '@mui/icons-material';

import { CustomToggleButton, CustomButton } from 'UI';

export default function Header({
    viewType = 'vertical',
    setViewType = () => {},
    setIsDrawerOpen = () => {},
    label = 'Danh sách sản phẩm',
    activeFilterCount = 0,
    ...otherProps
}) {
    return (
        <Box sx={{ width: '100%', px: 2 }}>
            <Stack
                p={1}
                sx={{
                    backgroundColor: { xs: 'white', lg: 'transparent' },
                    borderRadius: {
                        xs:
                            activeFilterCount === 0
                                ? '10px'
                                : '10px 10px 0px 0px',
                        lg: '0px',
                    },
                }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}>
                <Typography
                    component="h2"
                    sx={{ fontSize: 18, fontWeight: 600 }}>
                    {label}
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                    <CustomButton
                        id={'product-header-filter-button'}
                        variant="outlined"
                        color="disabled"
                        onClick={() => setIsDrawerOpen(true)}
                        styleProps={{
                            borderRadius: '20px',
                            display: { lg: 'none' },
                        }}
                        startIcon={<FilterListOutlinedIcon />}
                        children="Lọc"
                    />
                    <CustomToggleButton
                        id="view-type-custom-toggle-button"
                        sx={{
                            '&.MuiToggleButtonGroup-root': {
                                borderRadius: '20px',
                                alignItems: 'center',
                                border: '1px solid lightgrey',
                            },
                            '& .MuiToggleButtonGroup-grouped': {
                                p: '1px',

                                '&.Mui-disabled': {
                                    borderRadius: 0,
                                },
                                '&:first-of-type': {
                                    borderRadius: '20px',
                                    border: 'none',
                                    backgroundColor:
                                        viewType === 'vertical'
                                            ? 'rgba(209, 209, 209)'
                                            : 'transparent',
                                    p: 0.75,
                                },
                                '&:not(:first-of-type)': {
                                    borderRadius: '20px',
                                    border: 'none',
                                    backgroundColor:
                                        viewType === 'horizontal'
                                            ? 'rgba(209, 209, 209)'
                                            : 'transparent',
                                    p: 0.75,
                                },
                            },
                        }}
                        options={[
                            {
                                value: 'vertical',
                                icon:
                                    viewType === 'vertical' ? (
                                        <GridViewSharpIcon
                                            fontSize="small"
                                            sx={{
                                                color: 'black',
                                                width: 18,
                                                height: 18,
                                            }}
                                        />
                                    ) : (
                                        <GridViewOutlinedIcon
                                            fontSize="small"
                                            sx={{
                                                color: 'black',
                                                width: 18,
                                                height: 18,
                                            }}
                                        />
                                    ),
                            },
                            {
                                value: 'horizontal',
                                icon: (
                                    <ListAltIcon
                                        fontSize="small"
                                        sx={{
                                            color: 'black',
                                            width: 18,
                                            height: 18,
                                        }}
                                    />
                                ),
                            },
                        ]}
                        value={viewType}
                        onChange={() => {
                            setViewType((prev) =>
                                prev === 'vertical' ? 'horizontal' : 'vertical'
                            );
                        }}
                        exclusive
                        size="small"
                    />
                </Stack>
            </Stack>
        </Box>
    );
}
