import { Skeleton, Card, CardContent, CardActions } from '@mui/material';

import { borderWithTransition } from 'config/customCSS.js';

export default function ProductItemSkeleton({ ...otherProps }) {
    return (
        <Card
            variant="outlined"
            key={Math.random() * 1000 + 'skeleton-card-product-item'}
            sx={{
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                ...borderWithTransition,
            }}>
            <Skeleton
                variant="rounded"
                width={130}
                height={130}
                sx={{ mx: 'auto', my: 2 }}
            />
            <Skeleton
                variant="square"
                width={120}
                height={28}
                sx={{ borderRadius: '15px', ml: -2 }}
            />
            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    mt: -1,
                }}>
                <Skeleton variant="text" width={'90%'} height={24} />
                <Skeleton variant="text" width={'100%'} height={24} />
                <Skeleton variant="text" width={'60%'} height={24} />
                <Skeleton variant="text" width={'100%'} height={18} />
                <Skeleton variant="text" width={'20%'} height={18} />
            </CardContent>
            <CardActions disableSpacing>
                <Skeleton
                    variant="square"
                    width={'100%'}
                    height={36}
                    sx={{ mx: 1, borderRadius: '20px', mt: 1.25, mb: 0.5 }}
                />
            </CardActions>
        </Card>
    );
}
