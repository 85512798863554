import domain from 'config/domain';
import moment from 'moment';
import { QueryClient } from '@tanstack/react-query';
import { getAuthToken, logout } from 'utils/auth';

export const queryClient = new QueryClient();

const mapError = ({ status = 400, errorInfo = null }) => {
    let message = errorInfo?.title || getErrorMessage(status);
    let newError = new Error(message);
    newError.errorInfo = errorInfo || '';

    if (status === 401) {
        newError.errorInfo = 'Bạn cần đăng nhập Admin để tiếp tục!';
    }

    return newError;
};

export async function searchData({ signal, apiUrl }) {
    if (!apiUrl) throw mapError({ status: 400 });

    if (apiUrl.startsWith('/')) apiUrl = domain + apiUrl;
    const token = getAuthToken();
    const options = {
        method: 'GET',
        headers: { authorization: `JWT ${token}` },
        signal,
    };

    const response = await fetch(apiUrl, options);
    if (!response.ok) {
        if (response.status === 401) logout();
        const result = await response.json();
        throw mapError(result);
    }

    return response.json();
}

export async function queryData({
    signal,
    keyword = '',
    sortModel = [],
    filterModel = [],
    pageSize = 10,
    page = 1,
    apiUrl = '',
}) {
    if (!apiUrl) throw mapError({ status: 400 });

    if (apiUrl.startsWith('/')) apiUrl = domain + apiUrl;
    const token = getAuthToken();
    const options = {
        method: 'POST',
        headers: {
            authorization: `JWT ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            sortModel,
            filterModel: filterModel.map(
                ({ field, type, typeofField, connectType, value }) =>
                    typeofField === 'date'
                        ? {
                              field,
                              type,
                              connectType,
                              value: moment(value).format('YYYY-MM-DD'),
                          }
                        : { field, type, connectType, value }
            ),
            keyword,
            skip: pageSize * (page - 1),
            take: pageSize,
        }),
        signal,
    };

    const response = await fetch(apiUrl, options);
    if (!response.ok) {
        if (response.status === 401) logout();
        const result = await response.json();
        throw mapError(result);
    }

    return response.json();
}

export async function mutateData({ apiUrl, formData, signal }) {
    if (!apiUrl) throw mapError({ status: 400 });

    if (apiUrl.startsWith('/')) apiUrl = domain + apiUrl;

    const token = getAuthToken();
    const options = {
        method: 'POST',
        headers: {
            authorization: `JWT ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
        signal,
    };

    const response = await fetch(apiUrl, options);
    if (!response.ok) {
        if (response.status === 401) logout();
        const result = await response.json();
        throw mapError(result);
    }

    return response.json();
}

export async function deleteData({ apiUrl, key, signal }) {
    if (!key || !apiUrl) throw mapError({ status: 400 });

    if (apiUrl.startsWith('/')) apiUrl = domain + apiUrl;

    const token = getAuthToken();
    const options = {
        method: 'DELETE',
        headers: {
            authorization: `JWT ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ key }),
        signal,
    };

    const response = await fetch(apiUrl, options);
    if (!response.ok) {
        if (response.status === 401) logout();
        const result = await response.json();
        throw mapError(result);
    }

    return;
}

export async function uploadImage({ imageData, signal }) {
    if (!imageData) throw mapError({ status: 400 });

    // Step 1: Get the signed URL from the server
    const token = getAuthToken();
    const signedUrlRequest = {
        method: 'GET',
        headers: {
            authorization: `JWT ${token}`,
            'Content-Type': 'application/json',
        },
        signal,
    };

    const signedUrlResponse = await fetch(
        `${domain}/admin/aws/get-signed-url?imageType=${imageData.type}`,
        signedUrlRequest
    );
    if (!signedUrlResponse.ok) {
        if (signedUrlResponse.status === 401) logout();
        const result = await signedUrlResponse.json();
        throw mapError(result);
    }

    const { url: awsUrl, key: imageSrc } = await signedUrlResponse.json();

    // Step 2: Upload the image to AWS S3
    const awsUploadResponse = await fetch(awsUrl, {
        method: 'PUT',
        body: imageData,
        headers: { 'Content-Type': imageData.type },
    });
    if (!awsUploadResponse.ok) {
        throw mapError({
            status: 500,
            errorInfo:
                'Đã có lỗi xảy ra khi tải ảnh lên. Vui lòng thử lại sau!',
        });
    }

    // Step 3: Save the image information to the database
    const imageSaveRequest = {
        method: 'POST',
        body: JSON.stringify({ data: imageSrc }),
        headers: {
            authorization: `JWT ${token}`,
            'Content-Type': 'application/json',
        },
    };

    const imageSaveResponse = await fetch(
        `${domain}/admin/aws/post-image`,
        imageSaveRequest
    );
    if (!imageSaveResponse.ok) {
        if (imageSaveResponse.status === 401) logout();
        const result = await imageSaveResponse.json();
        throw mapError(result);
    }

    return imageSaveResponse.json();
}

const getErrorMessage = (code) => {
    const errorMessages = {
        400: 'Dữ liệu đầu vào chưa hợp lệ!',
        401: 'Dữ liệu yêu cầu phải đăng nhập để xác thực!',
        403: 'Bạn chưa được cấp phép để truy cập dữ liệu này!',
        404: 'Dữ liệu không tồn tại!',
        409: 'Dữ liệu đã tồn tại!',
        422: 'Lỗi xác thực dữ liệu đầu vào!',
        429: 'Lỗi gửi dữ liệu!',
        500: 'Lỗi server: Yêu cầu chưa thể xử lý!',
        502: 'Lỗi server: Không có tín hiệu!',
        503: 'Lỗi server: Server đang được bảo trì!',
    };

    return errorMessages[code] || '';
};
