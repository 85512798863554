import { createSlice } from '@reduxjs/toolkit';

const initialState = { dimBackground: false };

const systemSlice = createSlice({
    name: 'system',
    initialState,
    reducers: {
        toggleDimBackground(state, action) {
            state.dimBackground = action.payload;
        },
    },
});

export const systemActions = systemSlice.actions;

export default systemSlice.reducer;
