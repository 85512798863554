import { useState, useCallback } from 'react';
import domain from 'config/domain';

const useAWS = () => {
    const [loading, setLoading] = useState(false);

    const uploadImage = useCallback(async (imageData) => {
        setLoading(true);
        const access_token = localStorage.getItem('access_token');
        if (!imageData || !imageData?.type) {
            return {
                hasError: true,
                errorMessage: 'Không tìm thấy dữ liệu hình ảnh cần tải!',
                data: null,
            };
        }
        try {
            // fetch to khanhtrang server to get signedUrl from aws
            let response = await fetch(
                domain +
                    `/admin/aws/get-signed-url?imageType=${imageData.type}`,
                {
                    method: 'GET',
                    headers: { authorization: `JWT ${access_token}` },
                }
            );
            if (!response.ok) {
                setLoading(false);
                throw new Error(
                    'Đã có lỗi xảy ra trong quá trình tải ảnh, vui lòng thử lại sau!'
                );
            } else {
                let parsedData = await response.json();
                let { url, key } = parsedData;
                // fetch to aws server to putObject with signedUrl
                let putImageToAWS_S3 = await fetch(url, {
                    method: 'PUT',
                    body: imageData,
                    headers: { 'Content-Type': imageData.type },
                });
                if (!putImageToAWS_S3.ok) {
                    setLoading(false);
                    throw new Error(
                        'Đã có lỗi xảy ra trong quá trình tải ảnh, vui lòng thử lại sau!'
                    );
                } else {
                    setLoading(false);
                    return {
                        hasError: false,
                        errorMessage: null,
                        data: {
                            src:
                                'https://nhathuockhanhtrang.s3.ap-southeast-2.amazonaws.com/' +
                                key,
                        },
                    };
                }
            }
        } catch (error) {
            setLoading(false);
            return {
                hasError: true,
                errorMessage: error.message,
                data: null,
            };
        }
    }, []);
    const deleteImage = useCallback(async (key) => {
        setLoading(true);
        const access_token = localStorage.getItem('access_token');
        if (
            !Boolean(
                key &&
                    typeof key === 'string' &&
                    key?.split(
                        'https://nhathuockhanhtrang.s3.ap-southeast-2.amazonaws.com/'
                    )?.[1]
            )
        ) {
            return {
                hasError: true,
                errorMessage: 'Chưa có dữ liệu (key) cần xóa',
                data: null,
            };
        }
        try {
            // fetch to khanhtrang server to delete image
            let response = await fetch(domain + `/admin/aws/delete-image`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `JWT ${access_token}`,
                },
                body: JSON.stringify({
                    key: key?.split(
                        'https://nhathuockhanhtrang.s3.ap-southeast-2.amazonaws.com/'
                    )?.[1],
                }),
            });
            if (!response.ok) {
                setLoading(false);
                throw new Error(
                    'Đã có lỗi xảy ra trong quá trình xóa ảnh, vui lòng thử lại sau!'
                );
            } else {
                const parsedData = await response.json();
                setTimeout(() => {
                    setLoading(false);
                }, 300);
                return {
                    hasError: false,
                    errorMessage: null,
                    data: parsedData,
                };
            }
        } catch (error) {
            setLoading(false);
            return {
                hasError: true,
                errorMessage: error.message,
                data: null,
            };
        }
    }, []);
    return { loading, uploadImage, deleteImage };
};
export default useAWS;
