import { useEffect } from 'react';

import { useLocation, Outlet, useLoaderData } from 'react-router-dom';

import { getTokenDuration, logout } from 'utils/auth.js';

import PageLayout from './PageLayout.jsx';

export default function AdminMainScreen({ ...otherProps }) {
    let token = useLoaderData();
    // automatically logout if token expired
    useEffect(() => {
        if (!token) {
            return;
        }
        if (token === 'EXPIRED') {
            logout();
        }

        const tokenDuration = getTokenDuration();

        setTimeout(() => {
            logout();
        }, tokenDuration);
    }, [token]);

    const location = useLocation().pathname;
    //auto scroll to top if change screen
    useEffect(() => {
        if (location) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [location]);

    return (
        <PageLayout route={token ? 'admin' : 'shop'}>
            {token ? <Outlet /> : null}
        </PageLayout>
    );
}
