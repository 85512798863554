import { MobileStepper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
    KeyboardArrowLeft as KeyboardArrowLeftIcon,
    KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@mui/icons-material';

import { CustomButton } from 'UI';


export default function CustomMobileStepper({
    variant = null,
    activeStep = 0,
    onChangeStep = () => {},
    steps = 6,
    color = 'primary',
    size = 'medium',
    hideBackButton = false,
    hideNextButton = false,
    ...otherProps
}) {
    const theme = useTheme();
    const handleNext = () => {
        if (activeStep === steps - 1) return;
        onChangeStep(activeStep + 1);
    };

    const handleBack = () => {
        if (activeStep === 0) return;
        onChangeStep(activeStep - 1);
    };

    if (steps === 1) return null;

    let customVariant = variant || steps <= 6 ? 'dots' : 'text';
    return (
        <MobileStepper
            variant={customVariant}
            steps={steps}
            position="static"
            activeStep={activeStep}
            sx={{
                maxWidth: 250,
                width: '100%',
                backgroundColor: 'transparent',
                color: theme.palette[color].main,
                fontSize: size === 'small' ? 14 : size === 'medium' ? 15 : 16,
                '.MuiMobileStepper-dot': {
                    backgroundColor: theme.palette[color].lighter,
                },
                '.MuiMobileStepper-dotActive': {
                    backgroundColor: theme.palette[color].main,
                },
            }}
            backButton={
                <CustomButton
                    id="next-step-custom-button"
                    useIconButton={customVariant === 'dots'}
                    disableEffect
                    size={size}
                    color={color}
                    styleProps={{ display: hideBackButton ? 'none' : 'flex' }}
                    onClick={handleBack}
                    disabled={activeStep === 0}
                    startIcon={
                        customVariant !== 'dots' && (
                            <KeyboardArrowLeftIcon fontSize={size} />
                        )
                    }
                    children={
                        customVariant === 'dots' ? (
                            <KeyboardArrowLeftIcon fontSize={size} />
                        ) : (
                            'Trước'
                        )
                    }
                />
            }
            nextButton={
                <CustomButton
                    id="back-step-custom-button"
                    useIconButton={customVariant === 'dots'}
                    disableEffect
                    size={size}
                    color={color}
                    styleProps={{ display: hideNextButton ? 'none' : 'flex' }}
                    onClick={handleNext}
                    disabled={activeStep === steps - 1}
                    endIcon={
                        customVariant !== 'dots' && (
                            <KeyboardArrowRightIcon fontSize={size} />
                        )
                    }
                    children={
                        customVariant === 'dots' ? (
                            <KeyboardArrowRightIcon fontSize={size} />
                        ) : (
                            'Sau'
                        )
                    }
                />
            }
        />
    );
}
