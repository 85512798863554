import { useMemo } from 'react';

import { Button, IconButton } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';

import useCustomColor from 'hooks/useCustomColor';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function CustomUploadImageButton({
    id, // optional: string | should declare to prevent recalculate every renders

    useIconButton = false, // required
    children = 'Tải lên', // required if useIconButton: string | nodeElement
    onUpload = () => {}, // required

    variant = 'text', // optional: contained | text (default) | outlined
    color = 'primary', // optional: primary (default) | secondary | success | error | info | warning | disabled
    size = 'small', // optional: small (default) | medium | large

    styleProps = {}, // optional, overide styles from sx
    sx = {}, // remove sx from otherProps
    startIcon, // not supported
    endIcon, // not supported
    disableEffect = false, // remove effect: elevation, focusRipple, touchRipple and bgColor
    ...otherProps
}) {
    const theme = useTheme();
    let customPalleteColor = useCustomColor(color);

    let buttonStyles = useMemo(() => {
        let { main, light, lighter, contrastText } = customPalleteColor;
        let disabledColor = theme.palette['disabled'].light;
        let disabledBgColor = theme.palette['disabled'].lighter;
        let css = {
            contained: {
                backgroundColor: main,
                color: contrastText,
                textTransform: 'initial',
                ':hover': {
                    boxShadow: 1,
                    color: contrastText,
                    backgroundColor: disableEffect ? main : light,
                },
                '&:active': {
                    boxShadow: 'none',
                    backgroundColor: disableEffect ? main : light,
                    color: contrastText,
                },
                '&:focus': {
                    boxShadow: disableEffect ? 'none' : 1,
                    backgroundColor: disableEffect ? main : light,
                    color: contrastText,
                },
                '&.Mui-disabled': {
                    boxShadow: 'none',
                    color: disabledColor,
                    backgroundColor: disabledBgColor,
                },
            },
            text: {
                backgroundColor: 'transparent',
                color: main,
                textTransform: 'initial',
                ':hover': {
                    color: main,
                    backgroundColor: disableEffect ? 'transparent' : lighter,
                },
                '&:active': {
                    boxShadow: 'none',
                    backgroundColor: disableEffect ? 'transparent' : light,
                    color: contrastText,
                },
                '&:focus': {
                    boxShadow: 'none',
                    backgroundColor: disableEffect ? 'transparent' : light,
                    color: contrastText,
                },
                '&.Mui-disabled': {
                    boxShadow: 'none',
                    color: disabledColor,
                    backgroundColor: 'transparent',
                },
            },
            outlined: {
                backgroundColor: 'transparent',
                borderColor: light,
                color: main,
                textTransform: 'initial',
                ':hover': {
                    color: main,
                    backgroundColor: disableEffect ? 'transparent' : lighter,
                    borderColor: light,
                },
                '&:active': {
                    boxShadow: 'none',
                    backgroundColor: disableEffect ? 'transparent' : light,
                    color: contrastText,
                },
                '&:focus': {
                    boxShadow: 'none',
                    backgroundColor: disableEffect ? 'transparent' : light,
                    color: contrastText,
                },
                '&.Mui-disabled': {
                    color: disabledColor,
                    backgroundColor: 'transparent',
                    borderColor: 'rgba(0, 0, 0, 0.1)',
                },
            },
            iconButton: {
                backgroundColor: 'transparent',
                color: main,
                ':hover': {
                    color: main,
                    backgroundColor: disableEffect ? 'transparent' : lighter,
                },
                '&:active': {
                    boxShadow: 'none',
                    color: main,
                    backgroundColor: disableEffect ? 'transparent' : lighter,
                },
                '&:focus': {
                    boxShadow: 'none',
                    color: main,
                    backgroundColor: disableEffect ? 'transparent' : lighter,
                },
                '&.Mui-disabled': {
                    color: disabledColor,
                    backgroundColor: 'transparent',
                },
            },
        };

        return useIconButton ? css.iconButton : css[variant];
    }, [
        customPalleteColor,
        disableEffect,
        theme.palette,
        useIconButton,
        variant,
    ]);

    // const styles = getStyles({ variant, color });
    if (useIconButton) {
        return (
            <IconButton
                id={id || Math.random().toString(36).slice(2, 11)}
                component="label"
                aria-label="upload-file-icon-button"
                role={undefined}
                size={size}
                tabIndex={-1}
                disableElevation={disableEffect}
                disableRipple={disableEffect}
                disableFocusRipple={disableEffect}
                disableTouchRipple={disableEffect}
                sx={{ ...buttonStyles, ...styleProps }}
                {...otherProps}>
                <CloudUploadIcon />
                <VisuallyHiddenInput
                    type="file"
                    name="file"
                    accept="image/*"
                    onChange={onUpload}
                    data-testid="hidden-input"
                />
            </IconButton>
        );
    }
    return (
        <Button
            id={id || Math.random().toString(36).slice(2, 11)}
            component="label"
            aria-label="upload-file-button"
            role={undefined}
            variant={variant}
            size={size}
            tabIndex={-1}
            disableElevation={disableEffect}
            disableRipple={disableEffect}
            disableFocusRipple={disableEffect}
            disableTouchRipple={disableEffect}
            sx={{ ...buttonStyles, ...styleProps }}
            startIcon={<CloudUploadIcon />}
            {...otherProps}>
            {children}
            <VisuallyHiddenInput
                type="file"
                name="file"
                accept="image/*"
                onChange={onUpload}
                data-testid="hidden-input"
            />
        </Button>
    );
}
