import { Box, Stack, Typography } from '@mui/material';

import { CustomButton, CustomAvatar } from 'UI';

export default function NoFoundData({
    activeFilterCount = 0,
    resetFilter = () => {},
    ...otherProps
}) {
    return (
        <Stack
            spacing={3}
            justifyContent="center"
            alignItems="center"
            px={2}
            py={{ xs: 2, sm: 4 }}>
            <CustomAvatar
                src="../assets/images/pharmacy-illustration.png"
                variant="rounded"
                styleProps={{
                    width: '100%',
                    maxWidth: 500,
                    opacity: 0.5,
                    height: 200,
                }}
            />
            <Stack pt={2} spacing={0.5}>
                <Typography
                    sx={{ fontSize: 26, fontWeight: 600, textAlign: 'center' }}>
                    Không tìm thấy kết quả nào phù hợp
                </Typography>
                {activeFilterCount > 0 ? (
                    <Typography sx={{ fontSize: 26, textAlign: 'center' }}>
                        Hãy thử lại bằng cách thay đổi điều kiện lọc hoặc
                    </Typography>
                ) : (
                    <Typography sx={{ fontSize: 26, textAlign: 'center' }}>
                        Hãy thử tìm kiếm với từ khóa khác
                    </Typography>
                )}
            </Stack>
            <Box sx={{ display: activeFilterCount > 0 ? 'flex' : 'none' }}>
                <CustomButton
                    id="clear-all-filter-custom-button"
                    variant="contained"
                    onClick={resetFilter}
                    styleProps={{
                        width: 'fit-content',
                        borderRadius: '20px',
                    }}
                    children={
                        <Typography variant="h6" sx={{ fontSize: 16 }}>
                            Xóa tất cả bộ lọc
                        </Typography>
                    }
                />
            </Box>
        </Stack>
    );
}
