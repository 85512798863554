import { useState, useRef } from 'react';

import { Box, Typography } from '@mui/material';
import {
    Inventory as InventoryIcon,
    Store as StoreIcon,
    Storage as StorageIcon,
    Login as LoginIcon,
    Close as CloseIcon,
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon,
    Payments as PaymentsIcon,
    GroupsRounded as GroupsRoundedIcon,
    CollectionsBookmark as CollectionsBookmarkIcon,
    Person as PersonIcon,
    ShoppingBasket as ShoppingBasketIcon,
    PinDrop as PinDropIcon,
    WhatsApp as WhatsAppIcon,
    Facebook as FacebookIcon,
    YouTube as YouTubeIcon,
    Share as ShareIcon,
    Topic as TopicIcon,
    Newspaper as NewspaperIcon,
    Storefront as StorefrontIcon,
    Schedule as ScheduleIcon,
    Factory as FactoryIcon,
    Key as KeyIcon,
    MedicationLiquid as MedicationLiquidIcon,
    EmojiEvents as EmojiEventsIcon,
    Face as FaceIcon,
    Face2 as Face2Icon,
    Home as HomeIcon,
} from '@mui/icons-material';

import { useLocation, useNavigate } from 'react-router-dom';

import breadcrumbNameMapping from 'data/breadcrumbNameMapping.json';

import { CustomMenu, CustomTabs, CustomButton } from 'UI';

export default function AdminNavbar({ ...props }) {
    const navigate = useNavigate();

    let containerRef = useRef(null);

    const location = useLocation().pathname;
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuTabItem, setMenuTabItem] = useState([]);

    //only render in desktop screen

    const customButtonProps = {
        styleProps: { minWidth: 0, pl: 0.5 },
        component: 'div',
        'aria-controls': Boolean(anchorEl) ? 'admin-navbar-menuId' : undefined,
        'aria-haspopup': 'true',
        'aria-expanded': Boolean(anchorEl) ? 'true' : undefined,
    };
    let sx = {
        minWidth: 0,
        fontWeight: 500,
        fontSize: 16,
        position: 'relative',
        textTransform: 'initial',
        color: 'darkblue',
        '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 5,
            left: 0,
            width: '100%',
            height: '2px',
            backgroundColor: 'darkblue',
            transform: 'scaleX(0)',
            transformOrigin: 'bottom right',
            transition: 'transform 0.3s ease-in-out',
        },
        '&:hover::after': {
            transform: 'scaleX(1)',
            transformOrigin: 'bottom left',
        },
    };
    return (
        <Box
            ref={containerRef}
            sx={{
                width: '100%',
                backgroundColor: 'white',
            }}>
            <CustomTabs
                id="custom-admin-navbar"
                value={false}
                centered={true}
                options={[
                    {
                        id: 'store-page',
                        label: (
                            <CustomButton
                                id="store-page-button"
                                disableEffect
                                {...customButtonProps}
                                onClick={(event) => {
                                    setAnchorEl(event.currentTarget);
                                    setMenuTabItem([
                                        '/',
                                        '/thuoc',
                                        '/thuc-pham-chuc-nang',
                                        '/san-pham-khac',
                                        '/bai-viet-suc-khoe',
                                    ]);
                                }}
                                startIcon={<StoreIcon />}
                                children="Nhà thuốc"
                            />
                        ),
                        onClick: () => {},
                        sx,
                    },
                    {
                        id: 'page-management',
                        label: (
                            <CustomButton
                                id="page-management-button"
                                disableEffect
                                {...customButtonProps}
                                onClick={(event) => {
                                    setAnchorEl(event.currentTarget);
                                    setMenuTabItem([
                                        '/admin/homepage',
                                        '/admin/product-item',
                                        '/admin/manufacture',
                                        '/admin/ingredient',
                                        '/admin/article-item',
                                        '/admin/keyword',
                                        '/admin/market',
                                    ]);
                                }}
                                startIcon={<InventoryIcon />}
                                children="Quản lý Page"
                            />
                        ),
                        onClick: () => {},
                        sx,
                    },
                    {
                        id: 'list-page',
                        label: (
                            <CustomButton
                                id="list-page-button"
                                disableEffect
                                {...customButtonProps}
                                onClick={(event) => {
                                    setAnchorEl(event.currentTarget);
                                    setMenuTabItem([
                                        '/admin/staff',
                                        '/admin/commission-products',
                                        '/admin/salary',
                                    ]);
                                }}
                                startIcon={<StorageIcon />}
                                children="Danh sách"
                            />
                        ),
                        onClick: () => {},
                        sx,
                    },
                ]}
            />
            {Boolean(anchorEl) && (
                <CustomMenu
                    id="admin-navbar-menuId"
                    anchorEl={anchorEl}
                    density="comfortable"
                    onClose={() => setAnchorEl(null)}
                    items={Object.entries(breadcrumbNameMapping)
                        .filter(([key]) => menuTabItem.includes(key))
                        .map(([pathname, label]) => {
                            return {
                                name: pathname,
                                icon: iconMapping?.[pathname] ?? null,
                                label: (
                                    <Typography color='primary' sx={{ pr: 2 }}>
                                        {label}
                                    </Typography>
                                ),
                                subLabel: null,
                            };
                        })}
                    onSelectItem={(item) => {
                        setMenuTabItem([]);
                        navigate(item.name);
                    }}
                    getSelectedItem={(item) => item.name === location}
                />
            )}
        </Box>
    );
}
const iconProps = {
    fontSize: 'small',
    color: 'warning'
};
const iconMapping = {
    '/': <StorefrontIcon {...iconProps} />,
    '/thuoc': <TopicIcon {...iconProps} />,
    '/thuc-pham-chuc-nang': <TopicIcon {...iconProps} />,
    '/san-pham-khac': <TopicIcon {...iconProps} />,
    '/bai-viet-suc-khoe': <NewspaperIcon {...iconProps} />,
    '/thong-tin-tai-khoan': <PersonIcon {...iconProps} />,
    '/lich-su-mua-hang': <ShoppingBasketIcon {...iconProps} />,
    '/dia-chi-nhan-hang': <PinDropIcon {...iconProps} />,
    '/admin/homepage': <HomeIcon {...iconProps} />,
    '/admin/market': <StorefrontIcon {...iconProps} />,
    '/admin/product-item': <TopicIcon {...iconProps} />,
    '/admin/manufacture': <FactoryIcon {...iconProps} />,
    '/admin/ingredient': <MedicationLiquidIcon {...iconProps} />,
    '/admin/article-item': <NewspaperIcon {...iconProps} />,
    '/admin/keyword': <KeyIcon {...iconProps} />,
    '/admin/staff': <GroupsRoundedIcon {...iconProps} />,
    '/admin/commission-products': <CollectionsBookmarkIcon {...iconProps} />,
    '/admin/salary': <PaymentsIcon {...iconProps} />,
};
