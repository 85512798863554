import { useRef } from 'react';

import { Box } from '@mui/material';

import { useNavigate } from 'react-router-dom';

import { CustomTabs } from 'UI';

export default function ShopNavbar({ ...otherProps }) {
    const navigate = useNavigate();

    let containerRef = useRef(null);
    //only render in desktop screen

    let sx = {
        minWidth: 0,
        fontWeight: 500,
        fontSize: 16,
        position: 'relative',
        textTransform: 'initial',
        color: 'darkblue',
        '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 5,
            left: 0,
            width: '100%',
            height: '2px',
            backgroundColor: 'darkblue',
            transform: 'scaleX(0)',
            transformOrigin: 'bottom right',
            transition: 'transform 0.3s ease-in-out',
        },
        '&:hover::after': {
            transform: 'scaleX(1)',
            transformOrigin: 'bottom left',
        },
    };

    return (
        <Box
            ref={containerRef}
            sx={{
                width: '100%',
                backgroundColor: 'white',
            }}>
            <CustomTabs
                id="custom-shop-navbar"
                value={false}
                centered={true}
                options={[
                    {
                        id: 'drugs',
                        label: 'Thuốc',
                        onClick: () => {
                            navigate('/thuoc');
                        },
                        sx,
                    },
                    {
                        id: 'supplements',
                        label: 'Thực phẩm chức năng',
                        onClick: () => {
                            navigate('/thuc-pham-chuc-nang');
                        },
                        sx,
                    },
                    {
                        id: 'otherProducts',
                        label: 'Sản phẩm khác',
                        onClick: () => {
                            navigate('/san-pham-khac');
                        },
                        sx,
                    },
                    {
                        id: 'articles',
                        label: 'Bài viết sức khỏe',
                        onClick: () => {
                            navigate('/bai-viet-suc-khoe');
                        },
                        sx,
                    },
                ]}
            />
        </Box>
    );
}
