import { Badge } from '@mui/material';
import {
    PostAddRounded as PostAddRoundedIcon,
    FilterAlt as FilterAltIcon,
    Sort as SortIcon,
    AppsRounded as AppsRoundedIcon,
} from '@mui/icons-material';

import { CustomButton } from 'UI';
import useCustomColor from 'hooks/useCustomColor';

export default function DataActionButton({
    variant = 'contained', // string: contained | outlined | text
    useIconButton = false, //  use for CustomButton to render Button or IconButton
    icon = <AppsRoundedIcon />, // Mateial-icon
    type = 'form', // string
    buttonColor = 'primary', // string: primary (default) | secondary | success | info | warning | error | disabled | string color for custom
    badgeColor = 'orange', // string: primary (default) | secondary | success | info | warning | error | disabled |
    label = '', // string
    disabled = false,
    onClick = () => {},
    badgeContent = null,
    ...props
}) {
    let { main, light, contrastText } = useCustomColor(badgeColor);

    const iconMapping = {
        form: <PostAddRoundedIcon />,
        sort: (
            <SortIcon
                sx={{
                    transform: 'rotateY(45deg) rotateX(180deg)',
                }}
            />
        ),
        filter: <FilterAltIcon />,
    };
    let iconWithBadge = (
        <Badge
            badgeContent={badgeContent}
            sx={{
                '& .MuiBadge-badge': {
                    color: contrastText,
                    fontWeight: 600,
                    backgroundColor: light,
                    opacity: disabled ? 0.1 : 1,
                },
            }}>
            {iconMapping?.[type] ?? icon}
        </Badge>
    );
    return (
        <CustomButton
            id={type || label + '-dataActionButton'}
            useIconButton={useIconButton}
            variant={variant}
            color={buttonColor}
            disabled={disabled}
            onClick={onClick}
            startIcon={useIconButton ? null : iconMapping?.[type] ?? icon}
            children={
                useIconButton ? (
                    iconWithBadge
                ) : (
                    <b>
                        {label ||
                            type.slice(0, 1).toUpperCase() +
                                type.slice(1).toLowerCase()}
                        &nbsp;
                        {badgeContent ? (
                            <span style={{ color: main }}>
                                {`(${badgeContent})`}
                            </span>
                        ) : (
                            ''
                        )}
                    </b>
                )
            }
        />
    );
}
