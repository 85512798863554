import { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
    FormControl,
    FormLabel,
    FormControlLabel,
    FormHelperText,
    RadioGroup,
    Radio,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
    RadioButtonChecked as RadioButtonCheckedIcon,
    RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from '@mui/icons-material';

import useCustomColor from 'hooks/useCustomColor';

const StyledRadio = styled(Radio)(({ theme, ...props }) => {
    const { color } = props;
    let { main,  dark } = useCustomColor(color);

    return {
        '&.MuiRadio-root': {
            color: theme.palette['disabled'].main,
            ':hover': { color: dark },
            '&.Mui-checked': {
                color: main,
            },
            '&.Mui-disabled': {
                color: theme.palette['disabled'].light,
            },
        },
    };
});
/**
 * CustomRadio - A radio button group component with customizable styling and icon options.
 *
 * @component
 * @param {Object} props
 * @param {string} props.id - Unique ID for the form control.
 * @param {Array} props.options - Options to display as radio buttons.
 * @param {Function} props.formatOption - Function to format each option, returning an object with `name`, `label`.
 * @param {Function} props.getOptionDisabled - Function to determine if an option is disabled.
 * @param {string} props.value - Selected radio value.
 * @param {Function} props.onChange - Handler for value change events.
 * @param {string} [props.label] - Label for the radio group.
 * @param {string} [props.helperText] - Helper text below the radio group.
 * @param {string} [props.error] - Error text to display.
 * @param {string} [props.color='primary'] - Color scheme for the radio buttons.
 * @param {string} [props.size='small'] - Size of the radio buttons.
 * @param {string} [props.margin='none'] - Margin applied to the form control.
 * @param {node} [props.icon=<RadioButtonUncheckedIcon />] - Icon for unselected radio buttons.
 * @param {node} [props.checkedIcon=<RadioButtonCheckedIcon />] - Icon for selected radio buttons.
 * @param {string} [props.labelPlacement='end'] - Placement of labels relative to radio buttons.
 * @param {boolean} [props.disabled=false] - Disables all radio buttons.
 * @param {boolean} [props.required=false] - Marks the radio group as required.
 * @param {boolean} [props.row=false] - Displays radio buttons in a row if true.
 * @param {Object} [props.styleProps={}] - Additional styles for each radio option.
 * @param {Object} [props.FormControlProps] - Additional props for the FormControl component.
 * @param {Object} [props.FormLabelProps] - Additional props for the FormLabel component.
 * @param {Object} [props.RadioGroupProps] - Additional props for the RadioGroup component.
 * @param {Object} [props.FormHelperTextProps] - Additional props for the FormHelperText component.
 * @param {Object} [props.otherProps] - Other props for the FormControl component.
 */

function CustomRadio({
    id,
    options = [],
    formatOption = (opt) => ({
        name: opt, // this needs to match value props
        label: opt,
    }),
    getOptionDisabled = (option) => false,
    value = '',
    onChange = () => {},
    label: formLabel = '',
    helperText = '',
    error = '',
    color = 'primary',
    size = 'small',
    margin = 'none',
    icon = <RadioButtonUncheckedIcon />,
    checkedIcon = <RadioButtonCheckedIcon />,
    labelPlacement = 'end',
    disabled: formDisabled = false,
    required = false,
    row = false,
    styleProps = {},
    FormControlProps,
    FormLabelProps,
    FormGroupProps, // same as RadioGroupProps, should use RadioGroupProps instead
    RadioGroupProps,
    FormHelperTextProps,
    ...otherProps
}) {
    const handleChange = (event) => {
        onChange(event.target.value);
    };
    let defaultFormControlProps = {
        component: 'fieldset',
        variant: 'standard',
        disabled: formDisabled,
        required,
        margin,
        color,
        error: Boolean(error),
    };
    let formLabelProps = {
        component: 'legend',
        margin,
        ...FormLabelProps,
    };

    let radioProps = {
        size,
        color,
        margin,
        icon,
        checkedIcon,
        labelPlacement,
    };

    const formId = useMemo(
        () => id || Math.random().toString(36).slice(2, 11),
        [id]
    );

    return (
        <FormControl {...defaultFormControlProps} {...FormControlProps}>
            <FormLabel {...formLabelProps}>{formLabel}</FormLabel>
            {options.length > 0 && (
                <RadioGroup
                    row={row}
                    aria-labelledby={`${formId}-radio-group`}
                    name={`${formId}-radio-group`}
                    value={value}
                    onChange={handleChange}
                    {...RadioGroupProps}>
                    {options.map((opt, idx, array) => {
                        const { name, label } = formatOption(opt);
                        return (
                            <FormControlLabel
                                key={`${formId}-${idx + 1}`}
                                disabled={
                                    formDisabled || getOptionDisabled(opt)
                                }
                                labelPlacement={labelPlacement}
                                sx={{
                                    margin: margin === 'dense' ? '4px 0' : 0,
                                    ...styleProps,
                                }}
                                value={name}
                                control={
                                    <StyledRadio {...radioProps} name={name} />
                                }
                                label={label}
                            />
                        );
                    })}
                </RadioGroup>
            )}
            {(error || helperText) && (
                <FormHelperText
                    id={`${formId}-helper-text`}
                    {...FormHelperTextProps}>
                    {error || helperText}
                </FormHelperText>
            )}
        </FormControl>
    );
}

CustomRadio.propTypes = {
    id: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.any).isRequired,
    formatOption: PropTypes.func.isRequired,
    getOptionDisabled: PropTypes.func,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    helperText: PropTypes.string,
    error: PropTypes.string,
    color: PropTypes.oneOfType([
        PropTypes.oneOf([
            'primary',
            'secondary',
            'warning',
            'error',
            'info',
            'success',
            'disabled',
        ]),
        PropTypes.string,
    ]),
    size: PropTypes.oneOf(['small', 'medium']),
    margin: PropTypes.oneOf(['none', 'dense']),
    icon: PropTypes.node,
    checkedIcon: PropTypes.node,
    labelPlacement: PropTypes.oneOf(['end', 'start', 'top', 'bottom']),
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    row: PropTypes.bool,
    styleProps: PropTypes.object,
    FormControlProps: PropTypes.object,
    FormLabelProps: PropTypes.object,
    RadioGroupProps: PropTypes.object,
    FormHelperTextProps: PropTypes.object,
};

CustomRadio.defaultProps = {
    formatOption: (opt) => ({ name: opt, label: opt }),
    getOptionDisabled: () => false,
    label: '',
    helperText: '',
    error: '',
    color: 'primary',
    size: 'small',
    margin: 'none',
    icon: <RadioButtonUncheckedIcon />,
    checkedIcon: <RadioButtonCheckedIcon />,
    labelPlacement: 'end',
    disabled: false,
    required: false,
    row: false,
    styleProps: {},
};

export default CustomRadio;
