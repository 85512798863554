import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';

import useCustomColor from 'hooks/useCustomColor';

/**
 * CustomCircularProgress component that displays a circular progress indicator.
 *
 * @param {Object} props - The props for the component.
 * @param {number} [props.size=30] - The size of the circular progress indicator.
 * @param {number} [props.thickness=3] - The thickness of the circular progress indicator.
 * @param {string} [props.animationDuration='550ms'] - The duration of the animation.
 * @param {string} [props.color='primary'] - The color of the progress indicator.
 * @param {string} [props.backgroundColor] - The background color for the progress circle.
 * @param {Object} [otherProps] - Any other props to pass to the Box component.
 * @returns {JSX.Element} The rendered circular progress component.
 */

function CustomCircularProgress({
    size = 20,
    thickness = 3,
    animationDuration = '550ms',
    color = 'primary',
    backgroundColor = undefined,
    ...otherProps
}) {
    let customPalleteColor = useCustomColor(color);
    let { main, lighter } = customPalleteColor;

    return (
        <Box
            sx={{
                position: 'relative',
            }}
            {...otherProps}>
            <CircularProgress
                variant="determinate"
                sx={{
                    color: backgroundColor || lighter,
                }}
                size={size}
                thickness={thickness}
                value={100}
            />
            <CircularProgress
                variant="indeterminate"
                disableShrink
                sx={{
                    color: main,
                    animationDuration,
                    position: 'absolute',
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: 'round',
                    },
                }}
                size={size}
                thickness={thickness}
            />
        </Box>
    );
}

CustomCircularProgress.propTypes = {
    size: PropTypes.number,
    thickness: PropTypes.number,
    animationDuration: PropTypes.string,
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
};

export default CustomCircularProgress;
