import { createSlice } from '@reduxjs/toolkit';

const initialState = (() => {
    try {
        const history = localStorage.getItem('searchHistory');
        return history ? JSON.parse(history) : [];
    } catch (e) {
        console.error('Failed to parse search history from localStorage:', e);
        return [];
    }
})();

const searchHistorySlice = createSlice({
    name: 'searchHistory',
    initialState,
    reducers: {
        addSearchHistory: (state, action) => {
            const newValue = action.payload;
            // Remove newValue if it exists in the state
            const existingIndex = state.indexOf(newValue);
            if (existingIndex !== -1) {
                state.splice(existingIndex, 1); // Remove the existing value
            }
            // Add newValue to the beginning of the array
            state.unshift(newValue);
            localStorage.setItem('searchHistory', JSON.stringify(state));
        },
        deleteItemSearchHistory: (state, action) => {
            const updatedState = state.filter((i) => i !== action.payload);
            if (updatedState.length === 0) {
                localStorage.removeItem('searchHistory');
            } else {
                localStorage.setItem(
                    'searchHistory',
                    JSON.stringify(updatedState)
                );
            }
            return updatedState;
        },
        clearSearchHistory: (state) => {
            state.length = 0; // clear the array
            localStorage.removeItem('searchHistory');
        },
    },
});

export const searchHistoryActions = searchHistorySlice.actions;

export default searchHistorySlice.reducer; // use for congigure store
