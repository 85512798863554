import { Box, Typography, Grid2 } from '@mui/material';

import { useNavigate } from 'react-router-dom';

import { underlineWithTransition } from 'config/customCSS.js';

import CategoryCardItem from './CategoryCardItem.jsx';

export default function CategorySection({
    subCatgories = [],
    parentCategories = [],
    currentLevel = 1,
    ...otherProps
}) {
    const navigate = useNavigate();

    if (!currentLevel) {
        return parentCategories.map((parentCate, index) => {
            return (
                <Box
                    key={parentCate.name}
                    sx={{
                        backgroundColor: 'white',
                        py: 1,
                        px: { xs: 0, sm: 2 },
                        mx: { xs: 0, sm: 5 },
                        borderRadius: { xs: 0, sm: '5px' },
                    }}>
                    <Typography
                        variant="caption"
                        sx={{
                            fontSize: 16,
                            fontWeight: 500,
                            ml: { xs: 3, sm: 1 },
                            color: 'rgba(0, 0, 139, 0.8)',
                            ...underlineWithTransition,
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            navigate('/bai-viet-suc-khoe' + parentCate.slug);
                        }}>
                        {'Chủ đề: ' + parentCate.name}
                    </Typography>
                    <Box
                        sx={{
                            width: '100%',
                            px: { xs: 3, sm: 1 },
                            py: 1,
                        }}>
                        <Grid2 container spacing={0.25}>
                            {subCatgories
                                .filter((i) => i.parentName === parentCate.name)
                                .map((subCate) => {
                                    return (
                                        <Grid2
                                            size={{
                                                xs: 12,
                                                sm: 6,
                                                md: 4,
                                                lg: 3,
                                            }}
                                            key={Math.random() * 1000}>
                                            <CategoryCardItem
                                                id={subCate.id}
                                                name={subCate.name}
                                                slug={subCate.slug}
                                            />
                                        </Grid2>
                                    );
                                })}
                        </Grid2>
                    </Box>
                </Box>
            );
        });
    } else if (currentLevel === 1) {
        return (
            <Box
                sx={{
                    backgroundColor: 'white',
                    py: 2,
                    px: { xs: 3, sm: 2 },
                    mx: { xs: 0, sm: 5 },
                    borderRadius: { xs: 0, sm: '5px' },
                }}>
                <Grid2 container spacing={{ xs: 0.25, md: 1.5 }}>
                    {subCatgories.map((subCate) => {
                        return (
                            <Grid2
                                size={{ xs: 6, md: 4, xl: 3 }}
                                xs={6}
                                md={4}
                                xl={3}
                                zeroMinWidth
                                key={Math.random() * 1000}>
                                <CategoryCardItem
                                    id={subCate.id}
                                    name={subCate.name}
                                    slug={subCate.slug}
                                />
                            </Grid2>
                        );
                    })}
                </Grid2>
            </Box>
        );
    } else return null;
}
