import {
    Grid2,
    Link,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
    Diversity3 as Diversity3Icon,
    LocalShipping as LocalShippingIcon,
    History as HistoryIcon,
    Bolt as BoltIcon,
} from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';

import { CustomAvatar } from 'UI';

import { underlineWithTransition } from 'config/customCSS.js';

export default function Commitment({ ...otherProps }) {
    const theme = useTheme();
    return (
        <Grid2 container>
            {[
                {
                    icon: (
                        <Diversity3Icon
                            color="primary"
                            sx={{
                                width: 50,
                                height: 50,
                            }}
                        />
                    ),
                    header: 'Cam kết 100%',
                    subheader: 'hàng chính hãng và chất lượng',
                },
                {
                    icon: (
                        <LocalShippingIcon
                            color="primary"
                            sx={{
                                width: 50,
                                height: 50,
                            }}
                        />
                    ),
                    header: 'Miễn phí vận chuyển',
                    subheader: 'theo chính sách giao hàng',
                    router: '/chinh-sach-giao-hang',
                },
                {
                    icon: (
                        <BoltIcon
                            color="primary"
                            sx={{
                                width: 50,
                                height: 50,
                            }}
                        />
                    ),
                    header: 'Giao nhanh 4 giờ',
                    subheader: 'theo chính sách giao hàng',
                    router: '/chinh-sach-giao-hang',
                },
                {
                    icon: (
                        <HistoryIcon
                            color="primary"
                            sx={{
                                width: 50,
                                height: 50,
                            }}
                        />
                    ),
                    header: 'Đổi trả linh hoạt',
                    subheader: 'theo chính sách đổi trả',
                    router: '/chinh-sach-doi-tra-hang',
                },
            ].map(({ icon, header, subheader, router }, idx) => {
                return (
                    <Grid2
                        size={{ xs: 6, lg: 3 }}
                        key={'commitmentBox-commitment-box-header-' + idx}>
                        <ListItem
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', sm: 'row' },
                                alignItems: {
                                    xs: 'center',
                                    sm: 'flex-start',
                                },
                            }}>
                            <ListItemAvatar>
                                <CustomAvatar
                                    size={45}
                                    styleProps={{
                                        backgroundColor: 'transparent',
                                    }}>
                                    {icon}
                                </CustomAvatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={header}
                                primaryTypographyProps={{
                                    color: 'primary',
                                    fontWeight: 700,
                                    fontSize: { xs: 16, sm: 20 },
                                    textAlign: { xs: 'center', sm: 'left' },
                                }}
                                secondary={
                                    router ? (
                                        <Link
                                            component={RouterLink}
                                            to={router}
                                            underline="none"
                                            sx={{
                                                fontSize: { xs: 12, sm: 14 },
                                                fontWeight: 500,
                                                color: theme.palette.disabled
                                                    .main,
                                                textAlign: {
                                                    xs: 'center',
                                                    sm: 'left',
                                                },
                                                ...underlineWithTransition,
                                                backgroundImage:
                                                    'linear-gradient(#0a58ca 0 0)',
                                                cursor: 'pointer',
                                            }}>
                                            {subheader}
                                        </Link>
                                    ) : (
                                        <Typography
                                            component="span"
                                            color="disabled"
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: 500,
                                                textAlign: {
                                                    xs: 'center',
                                                    sm: 'left',
                                                },
                                            }}>
                                            {subheader}
                                        </Typography>
                                    )
                                }
                            />
                        </ListItem>
                    </Grid2>
                );
            })}
        </Grid2>
    );
}
