import {
    List,
    ListSubheader,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemAvatar,
    Collapse,
    ListItemText,
} from '@mui/material';

export default function CustomCollapseList({
    id, // required
    subHeader = '',
    open = false, // required
    options = [],
    /*
        required
        [{
            id,         // required
            onClick,    // required
            avatar,     // display avatar at the start
            icon,       // display icon at the start
            children,   // create a node element like ListItemText...
        }]
    */
    useListItemButton = true,
    alignItems = 'flex-start',
    disablePaddingListItem = false,
    ListProps = {},
    ...otherProps
}) {
    const StyledListItem = useListItemButton ? ListItemButton : ListItem;
    return (
        <Collapse in={open} timeout="auto" unmountOnExit {...otherProps}>
            <List
                component={useListItemButton ? 'nav' : 'ul'}
                aria-labelledby={id}
                subheader={
                    subHeader ? (
                        <ListSubheader
                            disableGutters
                            component="li"
                            id={id}
                            key={Math.random() * 1000}>
                            {subHeader}
                        </ListSubheader>
                    ) : undefined
                }
                {...ListProps}>
                {options.map((opt) => {
                    const {
                        onClick = useListItemButton ? () => {} : undefined,
                        ListItemProps = {},
                        secondaryAction = undefined,
                        avatar = null,
                        icon = null,
                        children = <ListItemText></ListItemText>,
                    } = opt;
                    return (
                        <StyledListItem
                            key={Math.random() * 1000}
                            alignItems={alignItems}
                            onClick={onClick}
                            {...(!useListItemButton && {
                                secondaryAction,
                                disablePadding: disablePaddingListItem,
                            })}
                            {...ListItemProps}>
                            {avatar && (
                                <ListItemAvatar>{avatar}</ListItemAvatar>
                            )}
                            {icon && (
                                <ListItemIcon
                                    sx={{
                                        minWidth: 20,
                                        mr: 1.5,
                                    }}>
                                    {icon}
                                </ListItemIcon>
                            )}
                            {children}
                        </StyledListItem>
                    );
                })}
            </List>
        </Collapse>
    );
}
