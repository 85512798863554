import { Typography, Stack, Box } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { CustomButton, CustomAvatar, CustomHelmet } from 'UI';

import PageLayout from '../../layout/main/PageLayout.jsx';

export default function Page404({ ...otheProps }) {
    const navigate = useNavigate();
    return (
        <>
            <CustomHelmet />
            <PageLayout route="shop">
                <Stack
                    spacing={3}
                    justifyContent="center"
                    alignItems="center"
                    px={2}
                    py={{ xs: 2, sm: 4 }}>
                    <CustomAvatar
                        src="../assets/images/pharmacy-illustration.png"
                        variant="rounded"
                        styleProps={{
                            width: '100%',
                            maxWidth: 500,
                            // opacity: 0.5,
                            height: 200,
                        }}
                    />
                    <Stack pt={2} spacing={0.5}>
                        <Typography
                            sx={{
                                fontSize: 26,
                                textAlign: 'center',
                                fontWeight: 600,
                            }}>
                            Đường dẫn đã hết hạn truy cập hoặc không tồn tại
                        </Typography>
                        <Typography
                            variant="caption"
                            sx={{ fontSize: 16, textAlign: 'center' }}>
                            Quý khách có thể liên hệ số Zalo{' '}
                            <a
                                href="tel:0704430919"
                                style={{ textDecoration: 'none' }}>
                                <b style={{ color: 'blue' }}>070.443.0919</b>
                            </a>{' '}
                            để được hỗ trợ
                        </Typography>
                    </Stack>
                    <CustomButton
                        id="go-to-home-custom-button"
                        variant='contained'
                        onClick={() => navigate('/')}
                        size="large"
                        styleProps={{
                            mt: 1,
                            py: 1,
                            width: 220,
                            fontSize: 20,
                            fontWeight: 600,
                            borderRadius: '30px',
                        }}
                        children="VỀ TRANG CHỦ"
                    />
                </Stack>
            </PageLayout>
        </>
    );
}
