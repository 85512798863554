import { Tabs, Tab } from '@mui/material';

export default function CustomTabs({
    id, // required
    value: tabValue, // required
    centered = false,
    options = [
        // { id, label } required
    ],
    onChange = () => {},
    ...otherProps
}) {
    return (
        <Tabs
            id={id}
            value={tabValue}
            onChange={onChange}
            {...(centered
                ? { centered: true }
                : { variant: 'scrollable', scrollButtons: false })}
            sx={{
                p: 0,
                '& .MuiTabs-indicator': {
                    backgroundColor: 'orange',
                    height: '3px',
                    borderRadius: '5px',
                },
                '& button.MuiTab-root': {
                    p: '12px 0px 8px 0px',
                    mx: 2,
                },
            }}
            {...otherProps}>
            {options.map(({ id, label, onClick = () => {}, ...tabProps }) => {
                return (
                    <Tab
                        id={id + '-tab'}
                        key={id}
                        label={label}
                        disableRipple
                        disableFocusRipple
                        onClick={onClick}
                        {...tabProps}
                    />
                );
            })}
        </Tabs>
    );
}
