import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Avatar } from '@mui/material';
import { BrokenImage as BrokenImageIcon } from '@mui/icons-material';

/**
 * CustomAvatar component that displays a user avatar with fallback options.
 *
 * @param {Object} props - The props for the component.
 * @param {string} [props.src='../assets/images/khanhtranglogo-02.png'] - The source of the avatar image.
 * @param {React.ReactNode} [props.children] - The content to display if the image fails to load.
 * @param {string} [props.alt=''] - The alternative text for the avatar image.
 * @param {'rounded' | 'square' | 'circular'} [props.variant='circular'] - The variant of the avatar shape.
 * @param {number} [props.size=24] - The size of the avatar (width & height).
 * @param {Object} [props.imgProps] - Additional props for the <img/> tag if the src exists.
 * @param {Object} [props.styleProps] - Additional styles to apply to the avatar.
 * @param {Object} [props.sx] - The sx prop for styling.
 * @param {Object} [otherProps] - Any other props to pass to the Avatar component.
 * @returns {JSX.Element} The rendered avatar component.
 */

function CustomAvatar({
    src = '../assets/images/khanhtranglogo-02.png',
    children = undefined, // required if src doesnot exist: string | Material-icon | nodeElement

    alt = '', // required if src exists
    variant = 'circular', // accepted: rounded | square | circular
    size = 24, // required: number (overide width & height with styleProps)
    imgProps, // optional, props of html tag <img/> if src exists

    styleProps = {}, // optional, overide styles from sx (size, backgroundColor...)
    sx = {}, // remove sx from otherProps
    ...otherProps
}) {
    const [newSrc, setNewSrc] = useState(
        src || '../assets/images/khanhtranglogo-02.png'
    );
    useEffect(() => {
        let timeout;
        if (src && !children) {
            timeout = setTimeout(() => {
                setNewSrc(src);
            }, 200);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [children, src]);

    const avatarStyles = useMemo(() => {
        return {
            width: size,
            height: size,
            backgroundColor: 'transparent',
            ...styleProps,
        };
    }, [size, styleProps]);

    if (children) {
        return (
            <Avatar variant={variant} sx={avatarStyles} {...otherProps}>
                {children ? children : <BrokenImageIcon />}
            </Avatar>
        );
    }

    return (
        <Avatar
            variant={variant}
            src={newSrc}
            imgProps={{
                ...imgProps,
                onError: () => {
                    setNewSrc('../assets/images/khanhtranglogo-02.png');
                },
            }}
            alt={alt || Math.random().toString(36).slice(2, 11)}
            sx={avatarStyles}
            {...otherProps}
        />
    );
}

CustomAvatar.propTypes = {
    src: PropTypes.string,
    children: PropTypes.node,
    alt: PropTypes.string,
    variant: PropTypes.oneOf(['rounded', 'square', 'circular']),
    size: PropTypes.number,
    imgProps: PropTypes.object,
    styleProps: PropTypes.object,
    sx: PropTypes.object,
};

export default CustomAvatar;
