import { Pagination } from '@mui/material';

export default function CustomPagination({
    page = 1,
    setPage = () => {},
    count = 1,
    ...props
}) {
    const handleChange = (event, value) => {
        setPage(value);
    };

    return (
        <Pagination
            shape="rounded"
            size="small"
            hidePrevButton
            hideNextButton
            count={count}
            page={page}
            onChange={handleChange}
            {...props}
        />
    );
}
