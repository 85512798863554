import { Stack, Box, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Inventory2 as Inventory2Icon } from '@mui/icons-material';

import { useQuery } from '@tanstack/react-query';
import { searchData } from 'utils/http.js';

import { underlineWithTransition } from 'config/customCSS.js';

import { CustomMasonry } from 'UI';
import ProductCard from '../products/ProductCard.jsx';

export default function SimilarProd({ subCategoryPath = '', ...otherProps }) {
    const theme = useTheme();
    const biggerSM = useMediaQuery(theme.breakpoints.up('sm')); // >=600
    const { data } = useQuery({
        queryKey: ['product-item', { subCategoryPath }],
        queryFn: ({ signal }) =>
            searchData({
                signal,
                apiUrl: `/product-item?subCategoryPath=${subCategoryPath}&type=1&skip=0&take=20`,
            }),
        staleTime: 30000,
        retry: 0,
        enabled: !!subCategoryPath,
    });

    if (!subCategoryPath || !(data && data.data.length > 0)) {
        return null;
    }
    return (
        <Box>
            <Stack pl={2} direction="row" alignItems="center" spacing={1}>
                <Inventory2Icon
                    fontSize="small"
                    sx={{
                        color: 'darkblue',
                    }}
                />
                <Typography
                    variant="h6"
                    sx={{ color: 'darkblue', ...underlineWithTransition }}>
                    Sản phẩm tương tự
                </Typography>
            </Stack>
            <CustomMasonry
                spacing={biggerSM ? 2 : 0.75}
                elevation={1}
                id="recentWatch-custom-masonry"
                columns={{ xs: 2, sm: 3, md: 4, xl: 6 }}
                items={data.data.map((prodData) => {
                    return {
                        id: prodData._id,
                        content: <ProductCard prodData={prodData} />,
                    };
                })}
                wrap={false}
            />
        </Box>
    );
}
