import { Helmet } from 'react-helmet-async';

export default function CustomHelmet({
    title = 'Nhà thuốc Khánh Trang',
    description = 'Nhà thuốc Khánh Trang là nhà thuốc chuyên bán lẻ Dược phẩm, Mỹ phẩm, Thực phẩm chức năng, Vật tư y tế. Hiện tại nhà thuốc Khánh Trang là đơn vị tiên phong trong thực hiện các tiêu chuẩn nhà thuốc GPP, cung cấp đa dạng sản phẩm với giá cả hợp lý, kết nối được nhiều chương trình ưu đãi hấp dẫn từ nhà cung cấp, phục vụ tận tâm, tận tình, giữ vững đạo đức ngành y dược.',
    image = 'https://nhathuockhanhtrang.s3.ap-southeast-2.amazonaws.com/nha_thuoc_khanh_trang.jpg',
    slug = 'https://nhathuockhanhtrang.com.vn',
    keywords = ' ',
    ...props
}) {
    return (
        <Helmet>
            <link rel="canonical" href={slug} />
            <meta property="og:locale" content="vi_VN" />
            <meta property="og:type" content="article" />
            <meta property="og:site_name" content="Nhà thuốc Khánh Trang" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="og:url" content={slug} />
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
        </Helmet>
    );
}
