import {
    Stack,
    Card,
    CardContent,
    CardActions,
    Typography,
    Link,
    Box,
    useMediaQuery,
} from '@mui/material';
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';

import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { recentWatchProdActions } from 'store/recentWatchProd-slice.js';

import { borderWithTransition } from 'config/customCSS.js';

import { CustomAvatar, CustomButton } from 'UI';

const customTheme = (outerTheme) =>
    createTheme({
        components: {
            MuiTypography: {
                styleOverrides: {
                    noWrap(styles) {
                        return {
                            whiteSpace: 'initial',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: String(
                                styles.ownerState['data-lines'] || '3'
                            ),
                            WebkitBoxOrient: 'vertical',
                        };
                    },
                },
            },
        },
    });

export default function ProductCard({
    prodData = {},
    viewType = 'vertical',
    ...otherProps
}) {
    let dispatch = useDispatch();
    let {
        _id = Math.random() * 1000,
        category = [],
        sku = '',
        slug = '',
        webName = '',
        primaryImage = {},
        specification = '',
    } = prodData;
    const navigate = useNavigate();
    const theme = useTheme();
    const biggerSM = useMediaQuery(theme.breakpoints.up('sm')); // >=600
    const biggerMD = useMediaQuery(theme.breakpoints.up('md')); // >=800
    const biggerLG = useMediaQuery(theme.breakpoints.up('lg')); // >=1000

    let categoryMaxLevelData =
        category.length > 0
            ? category.reduce((max, current) => {
                  if (!max) {
                      return current;
                  }
                  return current.level > max.level ? current : max;
              }, null)
            : null;
    let categoryName = categoryMaxLevelData?.name || null;
    let categorySlug = categoryMaxLevelData?.slug;
    let categoryId = categoryMaxLevelData?.id || null;

    let href = `/san-pham${slug}`;

    const handleClick = (e) => {
        e.preventDefault();
        dispatch(recentWatchProdActions.addRecentWatch(sku));
        navigate(href);
    };

    let hasMoreContent =
        biggerLG || !biggerSM || (biggerMD && viewType !== 'vertical');

    return (
        <Card
            variant="outlined"
            key={_id + '-card-product-item'}
            sx={{
                border: 'none',
                ...borderWithTransition,
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: viewType === 'vertical' ? 'column' : 'row',
                width: '100%',
                borderRadius: '12px',
            }}>
            <Link
                component={RouterLink}
                to={href}
                onClick={handleClick}
                underline="none"
                sx={{
                    fontSize: 14,
                    fontWeight: 600,
                    color: 'black',
                }}>
                <CustomAvatar
                    styleProps={{ mx: 'auto', p: 1 }}
                    variant="square"
                    src={primaryImage?.mainSrc || ''}
                    size={viewType === 'vertical' ? 160 : 200}
                    alt={primaryImage?.altText || ''}
                />
            </Link>
            <Box>
                {specification && (
                    <Stack spacing={1} ml={viewType === 'vertical' ? -2 : 0}>
                        <Typography
                            noWrap
                            sx={{
                                fontSize: 12,
                                backgroundColor: 'aliceblue',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                ...(viewType === 'vertical'
                                    ? {
                                          p: '5px 20px 5px 32px',
                                          borderRadius: '15px',
                                          color: 'blue',
                                          width: 'fit-content',
                                          maxWidth: '80%',
                                      }
                                    : {
                                          p: '10px 0 8px 18px',
                                          borderRadius: '0 9px 0 9px',
                                          maxWidth: '100%',
                                          fontWeight: 500,
                                      }),
                            }}>
                            {specification}
                        </Typography>
                    </Stack>
                )}
                <CardContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        mt: viewType === 'vertical' ? 0 : -1,
                        pt: viewType === 'vertical' ? 1 : 2,
                        pb: 1,
                        px: { xs: 1.25, sm: 2 },
                    }}>
                    <ThemeProvider theme={customTheme(theme)}>
                        <Typography
                            noWrap
                            component="h3"
                            data-lines={hasMoreContent ? '3' : '2'}
                            sx={{
                                height: hasMoreContent ? 72 : 48,
                            }}>
                            <Link
                                component={RouterLink}
                                to={href}
                                onClick={handleClick}
                                underline="none"
                                sx={{
                                    fontSize: 14,
                                    fontWeight: 500,
                                    textTransform: 'initial',
                                    textAlign: 'left',
                                    color: theme.palette.primary.main,
                                    ':hover': {
                                        color: theme.palette.primary.main,
                                    },
                                }}>
                                {webName}
                            </Link>
                        </Typography>
                    </ThemeProvider>

                    <ThemeProvider theme={customTheme(theme)}>
                        <Typography
                            noWrap
                            component="h6"
                            data-lines={hasMoreContent ? '2' : '1'}
                            sx={{
                                height: hasMoreContent ? 44 : 24,
                            }}>
                            <Link
                                component={RouterLink}
                                to={categorySlug}
                                sx={{
                                    pt: 0.5,
                                    fontSize: 14,
                                    textTransform: 'initial',
                                    textAlign: 'left',
                                    color: theme.palette.disabled.main,
                                    ':hover': {
                                        color: theme.palette.disabled.dark,
                                        fontWeight: 500,
                                        textDecoration: 'underline',
                                    },
                                }}
                                underline="none">
                                {categoryName}
                            </Link>
                        </Typography>
                    </ThemeProvider>
                </CardContent>
                <CardActions disableSpacing sx={{ px: '10px' }}>
                    <CustomButton
                        id={_id + '-view-detail-custom-button'}
                        variant="contained"
                        styleProps={{
                            borderRadius: '20px',
                            fontWeight: 500,
                            mb: 0.5,
                            minWidth: '120px',
                            py: 0.5,
                        }}
                        fullWidth={viewType === 'vertical'}
                        onClick={handleClick}
                        children="Xem chi tiết"
                    />
                </CardActions>
            </Box>
        </Card>
    );
}
