import {  useMemo } from 'react';
import PropTypes from 'prop-types';

import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Input,
    OutlinedInput,
    FilledInput,
    FormHelperText,
    InputAdornment,
    Stack,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { darken } from '@mui/system';
import {
    ArrowDropDown as ArrowDropDownIcon,
    Clear as ClearIcon,
} from '@mui/icons-material';

import useCustomColor from 'hooks/useCustomColor';

import { CustomChip } from 'UI';
import { OptionRow } from 'template';

/**
 * Styled input component that supports different variants: 'standard', 'outlined', and 'filled'.
 */
const StyledInput = styled((props) => {
    const InputComponent =
        props.variant === 'standard'
            ? Input
            : props.variant === 'outlined'
            ? OutlinedInput
            : FilledInput;

    return <InputComponent {...props} />;
})(({ theme, ...props }) => {
    let { main, lighter } = useCustomColor(props.color);

    return {
        ...(props.variant === 'standard' && {
            '&.MuiInputBase-root': {
                color: main,
                height: '100%',
                alignItems: 'flex-start',
                ':hover:not(.Mui-disabled, .Mui-error):before': {
                    borderWidth: '1px',
                    borderColor: main,
                },
                '::after': {
                    borderBottom: `2px solid ${main}`,
                },
                ...(props.endAdornment && {
                    '& .MuiSelect-iconOpen': { transform: 'none' },
                }),
            },
        }),
        ...(props.variant === 'filled' && {
            '&.MuiFilledInput-root': {
                backgroundColor: lighter,
                color: theme.palette['disabled'].main,
                height: '100%',
                // alignItems: 'flex-start',
                ':hover:not(.Mui-disabled, .Mui-focused)': {
                    backgroundColor: darken(lighter, 0.03),
                    color: theme.palette['disabled'].main,
                },
                '.Mui-disabled': {
                    backgroundColor: theme.palette['disabled'].lighter,
                },
                ...(props.endAdornment && {
                    '& .MuiSelect-iconOpen': { transform: 'none' },
                }),
            },
        }),
        ...(props.variant === 'outlined' && {
            '&.MuiOutlinedInput-root': {
                color: main,
                height: '100%',
                // alignItems: 'center',
                '&:hover:not(.Mui-disabled, .Mui-error) fieldset': {
                    borderColor: main,
                },
                '&.Mui-focused fieldset': {
                    borderColor: main,
                },
                '&.Mui-error fieldset': {
                    borderColor: theme.palette.error.main,
                },
                '&.Mui-disabled fieldset': {
                    borderColor: theme.palette.disabled.light,
                },
                ...(props.endAdornment && {
                    '& .MuiSelect-iconOpen': { transform: 'none' },
                }),
            },
        }),
    };
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

/**
 * CustomSelect component for single or multiple selections with customizable styles.
 * @component
 * @param {Object} props - Component props
 * @param {string} [props.name='name'] - Name of the input field
 * @param {string} [props.id] - Unique ID for the component
 * @param {Array} props.options - Array of options to display in the Select, must be an array includes only string elements
 * @param {function} props.formatOption - Function to convert raw option to expected object with `name` and `label` properties, add subLabel = null to hide subLabel = name (default)
 * @param {function} props.getOptionDisabled - whether an option is disabled
 * @param {boolean} [props.multiple=true] - Whether multiple selections are allowed
 * @param {Object} [props.value] - Current selected value(s) (controlled component)
 * @param {function} props.onChange - Callback when selected value changes
 * @param {string} [props.label=''] - Label for the input field
 * @param {string} [props.error=''] - Error message for input field
 * @param {string} [props.helperText=''] - Helper text for input field
 * @param {string} [props.placeholder=''] - Placeholder text for input field
 * @param {string} [props.initialText=''] - The text before the first option to instruct user
 * @param {Object} [props.inputProps={}] - Additional props for input
 * @param {Object} [props.InputLabelProps={ shrink: true }] - Props for input label
 * * @param {Object} [props.FormControlProps] - Props for the FormControl component
 * @param {Object} [props.FormHelperTextProps={}] - Props for form helper text
 * @param {Object} [props.inputRef] - Ref for the input element
 * @param {boolean} [props.autoWidth=false] - Whether the input should take auto width based on current value
 * @param {boolean} [props.displayEmpty=true] - Whether show the placeholder when value is empty.
 * @param {boolean} [props.displayCheckmark=true] - Whether show the checkmark before each option.
 * @param {boolean} [props.fullWidth=true] - Whether the input should take full width
 * @param {boolean} [props.required=false] - Whether the input is required
 * @param {boolean} [props.readOnly=false] - Whether the input is read-only
 * @param {boolean} [props.disabled=false] - Whether the input is disabled
 * @param {function} props.onBlur - Callback when blured the input
 * @param {function} props.onFocus - Callback when focused the input
 * @param {string} [props.variant='standard'] - Input variant: 'filled', 'outlined', or 'standard'
 * @param {string} [props.size='small'] - Size of the input: 'small' or 'medium'
 * @param {string} [props.color='primary'] - Color of the input
 * @param {string} [props.margin='none'] - Margin style for the input
 * @param {React.Node} [props.startAdornment] - Start adornment (e.g., icon)
 * @param {React.Node} [props.endAdornment] - End adornment (e.g., icon)
 * @param {Object} [props.openIconProps={}] - Props for styling open icon of Select
 * @returns {JSX.Element} CustomSelect component.
 */
function CustomSelect({
    name = '',
    id,
    options = [],
    formatOption = (opt) => ({
        name: opt,
        label: opt,
        subLabel: null,
    }),
    getOptionDisabled = (option) => false,
    multiple = true,
    value: valueProps = multiple ? [] : '',
    onChange = () => {},

    label = '',
    error = '',
    helperText = '',
    placeholder = '',
    initialText = '',
    inputProps = {},
    InputLabelProps = { shrink: true },
    FormControlProps = {},
    FormHelperTextProps = {},
    inputRef,
    autoWidth = false,
    displayEmpty = true,
    displayCheckmark = true,
    fullWidth = true,
    required = false,
    readOnly = false,
    disabled: formDisabled = false,

    onBlur = () => {},
    onFocus = () => {},

    variant = 'standard',
    color = 'primary',
    size = 'small',
    margin = 'none',
    startAdornment = null,
    endAdornment = null,
    openIconProps = {
        IconComponent: ArrowDropDownIcon, // The icon that displays the arrow, accept name of icon only, not a NodeElement <ArrowDropDown/>
        sx: {
            '& .MuiSelect-iconOpen': { transform: 'rotate(180deg' }, // use to disable transform rotate(180deg) of IconComponent when opening
        },
    },
    ...otherProps
}) {
    const handleMultipleChange = (event) => {
        const v = event.target.value; // array of values in selected MenuItems

        const selectedValues = typeof v === 'string' ? v.split(',') : v;
        if (
            selectedValues.length === 0 ||
            String(selectedValues.slice(-1)[0]).length === 0
        ) {
            onChange([]);
        } else {
            onChange(selectedValues);
        }
    };
    const handleSingleChange = (event) => {
        let v = event.target.value; // equal to value in selected MenuItem

        onChange(v);
    };
    const handleChange = (e) => {
        if (multiple) {
            handleMultipleChange(e);
        } else {
            handleSingleChange(e);
        }
    };

    const selectId = useMemo(
        () => id || Math.random().toString(36).slice(2, 11),
        [id]
    );

    const defaultFormControlProps = {
        variant,
        fullWidth,
        disabled: formDisabled,
        required,
        margin,
        color,
        size,
        error: Boolean(error),
    };
    const defaultSelectProps = {
        id: selectId,
        labelId: selectId + '-labelId',
        multiple,
        autoWidth,
        displayEmpty,
        name,
        variant,
        margin,
        color,
        label,
        disabled: formDisabled,
        error: Boolean(error),
        inputRef,
        inputProps,
        'aria-describedby': `${selectId}-helper-text`,
        readOnly,
        fullWidth,
        onBlur,
        onFocus,
        IconComponent: endAdornment ? () => null : openIconProps.IconComponent,
    };
    const defaultInputProps = {
        name,
        variant,
        margin,
        color,
        label,
        disabled: formDisabled,
        error: Boolean(error),
        readOnly,
        fullWidth,
        placeholder,
    };
    let isPlaceholderDisabled = useMemo(() => {
        if (multiple) {
            return valueProps?.length > 0;
        } else {
            return Boolean(String(valueProps).trim());
        }
    }, [multiple, valueProps]);

    const { lighter } = useCustomColor(color);

    return (
        <FormControl
            id={selectId + '-form-control'}
            {...defaultFormControlProps}
            {...FormControlProps}>
            <InputLabel
                id={selectId + '-labelId'}
                htmlFor={selectId}
                {...InputLabelProps}>
                {label}
            </InputLabel>
            <Select
                {...defaultSelectProps}
                labelId={id + '-label'}
                value={valueProps}
                onChange={handleChange}
                renderValue={(selected) => {
                    if (
                        (!multiple && !String(selected)) ||
                        (multiple && selected.length === 0)
                    ) {
                        return <em>{placeholder}</em>;
                    }
                    return multiple ? (
                        <Stack direction="row" flexWrap="wrap" spacing={0.5}>
                            {[selected].flat().map((option, idx) => (
                                <CustomChip
                                    key={option.name + idx}
                                    size={size}
                                    variant={
                                        variant !== 'standard'
                                            ? variant
                                            : 'outlined'
                                    }
                                    disabled={formDisabled}
                                    color={color}
                                    label={formatOption(option).label}
                                />
                            ))}
                        </Stack>
                    ) : (
                        formatOption(selected).label
                    );
                }}
                input={
                    <StyledInput
                        {...defaultInputProps}
                        startAdornment={
                            startAdornment && (
                                <InputAdornment position="start">
                                    {startAdornment}
                                </InputAdornment>
                            )
                        }
                        endAdornment={
                            endAdornment && (
                                <InputAdornment position="end">
                                    {endAdornment}
                                </InputAdornment>
                            )
                        }
                    />
                }
                MenuProps={{
                    transformOrigin: { horizontal: 'right', vertical: 'top' },
                    anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
                    PaperProps: {
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                        },
                        width: '100%',
                    },
                }}>
                {displayEmpty && (initialText || placeholder) && (
                    <MenuItem
                        disableGutters
                        disableRipple
                        dense
                        divider={isPlaceholderDisabled}
                        disabled={!isPlaceholderDisabled}
                        value={''}
                        sx={{
                            '&.MuiMenuItem-root.Mui-disabled': {
                                opacity: 1,
                                backgroundColor: 'transparent',
                            },
                        }}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                            px={'9px'}>
                            {isPlaceholderDisabled && (
                                <ClearIcon fontSize="small" />
                            )}
                            {isPlaceholderDisabled ? (
                                <em>Bỏ chọn</em>
                            ) : (
                                initialText || placeholder
                            )}
                        </Stack>
                    </MenuItem>
                )}
                {options.map((opt, idx) => {
                    let {
                        label,
                        name,
                        subLabel = undefined,
                    } = formatOption(opt);
                    return (
                        <MenuItem
                            key={name}
                            divider
                            disabled={formDisabled || getOptionDisabled(opt)}
                            disableGutters
                            value={name}
                            sx={{
                                '&.MuiMenuItem-root': {
                                    '&.Mui-selected:hover': {
                                        backgroundColor: darken(lighter, 0.1),
                                    },
                                    ':hover': {
                                        backgroundColor: lighter,
                                    },
                                    '&.Mui-focused': {
                                        backgroundColor: darken(lighter, 0.1),
                                    },
                                },
                            }}>
                            <OptionRow
                                label={label}
                                subLabel={
                                    subLabel !== undefined ? subLabel : name
                                }
                                selected={
                                    multiple
                                        ? valueProps.indexOf(name) > -1
                                        : valueProps === name
                                }
                                displayCheckmark={displayCheckmark}
                                displayHighlight={false}
                                checkboxProps={{ color }}
                            />
                        </MenuItem>
                    );
                })}
            </Select>
            {(error || helperText) && (
                <FormHelperText
                    id={`${selectId}-helper-text`}
                    {...FormHelperTextProps}>
                    {error || helperText}
                </FormHelperText>
            )}
        </FormControl>
    );
}

CustomSelect.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
        ])
    ).isRequired,
    formatOption: PropTypes.func.isRequired,
    getOptionDisabled: PropTypes.func,
    multiple: PropTypes.bool,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func,
    label: PropTypes.string,
    error: PropTypes.string,
    helperText: PropTypes.string,
    placeholder: PropTypes.string,
    initialText: PropTypes.any,
    inputProps: PropTypes.object,
    InputLabelProps: PropTypes.object,
    FormControlProps: PropTypes.object,
    FormHelperTextProps: PropTypes.object,
    inputRef: PropTypes.any,
    autoWidth: PropTypes.bool,
    displayEmpty: PropTypes.bool,
    fullWidth: PropTypes.bool,
    required: PropTypes.bool,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
    color: PropTypes.oneOfType([
        PropTypes.oneOf([
            'primary',
            'secondary',
            'success',
            'error',
            'info',
            'warning',
            'disabled',
        ]),
        PropTypes.string, // For custom colors
    ]),
    size: PropTypes.oneOf(['small', 'medium']),
    margin: PropTypes.oneOf(['none', 'dense', 'normal']),
    startAdornment: PropTypes.node,
    endAdornment: PropTypes.node,
    openIconProps: PropTypes.object,
};

CustomSelect.defaultProps = {
    name: '',
    options: [],
    formatOption: (opt) => ({ name: opt, label: opt }),
    getOptionDisabled: () => false,
    multiple: true,
    value: [],
    onChange: () => {},
    label: '',
    error: '',
    helperText: '',
    placeholder: '',
    initialText: '',
    inputProps: {},
    InputLabelProps: { shrink: true },
    FormControlProps: {},
    FormHelperTextProps: {},
    autoWidth: false,
    displayEmpty: true,
    fullWidth: true,
    required: false,
    readOnly: false,
    disabled: false,
    onBlur: () => {},
    onFocus: () => {},
    variant: 'standard',
    color: 'primary',
    size: 'small',
    margin: 'none',
    startAdornment: null,
    endAdornment: null,
    openIconProps: {
        IconComponent: ArrowDropDownIcon,
        sx: { '& .MuiSelect-iconOpen': { transform: 'rotate(180deg)' } },
    },
};

export default CustomSelect;
