import { Modal, Backdrop, Fade, Box } from '@mui/material';

export default function CustomModal({
    open = false, // required
    onClose = () => {}, // required
    children, // required
    id, // required
    styleProps = {},
    ...otherProps
}) {
    const labelledby = open && id ? `modal-${id}-title` : undefined;
    const describedby = open && id ? `modal-${id}-description` : undefined;

    return (
        <Modal
            open={open}
            onClose={onClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
            aria-labelledby={labelledby}
            aria-describedby={describedby}
            {...otherProps}>
            <Fade in={open}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        backgroundColor: 'white',
                        borderRadius: '5px',
                        border: '1px solid lightgrey',
                        boxShadow: 20,
                        p: 2,
                        ...styleProps,
                    }}>
                    {children}
                </Box>
            </Fade>
        </Modal>
    );
}
