import { Fragment, useState } from 'react';

import {
    Box,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Typography,
    Stack,
    Divider,
} from '@mui/material';
import {
    Login as LoginIcon,
    Close as CloseIcon,
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon,
    Payments as PaymentsIcon,
    GroupsRounded as GroupsRoundedIcon,
    CollectionsBookmark as CollectionsBookmarkIcon,
    Person as PersonIcon,
    ShoppingBasket as ShoppingBasketIcon,
    PinDrop as PinDropIcon,
    WhatsApp as WhatsAppIcon,
    Facebook as FacebookIcon,
    YouTube as YouTubeIcon,
    Share as ShareIcon,
    Topic as TopicIcon,
    Newspaper as NewspaperIcon,
    Storefront as StorefrontIcon,
    Schedule as ScheduleIcon,
    Factory as FactoryIcon,
    Key as KeyIcon,
    MedicationLiquid as MedicationLiquidIcon,
    EmojiEvents as EmojiEventsIcon,
    Face as FaceIcon,
    Face2 as Face2Icon,
    Home as HomeIcon,
} from '@mui/icons-material';

import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { customScrollbar, drawerWidth } from 'config/customCSS.js';
import breadcrumbNameMapping from 'data/breadcrumbNameMapping.json';
import cateLv2 from 'data/cateLv2.json';
import articleLv1 from 'data/articleLv1.json';

import { CustomButton, CustomAvatar, CustomCollapseList } from 'UI';

import LogoBrand from '../LogoBrand.jsx';

function CustomCollapse({
    primaryText = '',
    open = false,
    mainSlug = '',
    onCollapse = () => {},
    onNavigate = () => {},
    toggleDrawer = () => {},
    options = [],
    ...otherProps
}) {
    const navigate = useNavigate();
    const location = useLocation().pathname;
    return (
        <>
            <ListItem
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    px: 2,
                }}>
                <CustomButton
                    id={`${primaryText}-navigate-button`}
                    disableEffect
                    fullWidth
                    styleProps={{ textAlign: 'left', p: 0 }}
                    onClick={onNavigate}>
                    <ListItemText
                        primary={primaryText}
                        primaryTypographyProps={{
                            variant: 'subtitle2',
                            color:
                                options
                                    .filter((i) => typeof i === 'string')
                                    .includes(location) || location === mainSlug
                                    ? 'blue'
                                    : 'black',
                            fontSize: 16,
                            pl: 0.5,
                        }}
                    />
                </CustomButton>
                <CustomButton
                    id={`${primaryText}-expand-button`}
                    useIconButton
                    styleProps={{ p: 0 }}
                    onClick={onCollapse}
                    children={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                />
            </ListItem>
            <CustomCollapseList
                id={Math.random() * 1000}
                open={open}
                ListProps={{
                    sx: {
                        backgroundColor: 'aliceblue',
                        mx: 2.5,
                        borderRadius: '5px',
                        border: '0.5px dotted rgba(0, 0, 139, 0.2)',
                        p: 0,
                    },
                }}
                useListItemButton={false}
                disablePaddingListItem={true}
                options={options
                    .map((opt, idx) => {
                        if (idx !== options.length - 1) {
                            return [
                                opt,
                                {
                                    children: (
                                        <Divider
                                            sx={{
                                                width: '100%',
                                                height: '1px',
                                                color: 'lightgrey',
                                            }}
                                        />
                                    ),
                                },
                            ];
                        } else return opt;
                    })
                    .flat()
                    .map((opt) => {
                        if (typeof opt !== 'string') {
                            return {
                                onClick: () => {},
                                icon: opt?.icon || null,
                                children: opt.children,
                                ListItemProps: {
                                    sx: {
                                        padding: '0px !important',
                                        color:
                                            location === opt ? 'blue' : 'black',
                                        cursor: 'pointer',
                                        ...(opt?.ListItemProps?.sx || {}),
                                    },
                                    component: 'li',
                                },
                            };
                        }
                        return {
                            onClick: () => {
                                toggleDrawer();
                                navigate(opt);
                            },
                            icon: opt?.icon || iconMapping?.[opt] || null,
                            children: (
                                <ListItemText
                                    primary={
                                        breadcrumbNameMapping?.[opt] ||
                                        cateLv2
                                            .concat(articleLv1)
                                            .find(
                                                (i) =>
                                                    i.slug ===
                                                    '/' + opt.split('/')[2]
                                            )?.name ||
                                        ''
                                    }
                                />
                            ),
                            ListItemProps: {
                                sx: {
                                    padding: '4px 16px !important',
                                    color: location === opt ? 'blue' : 'black',
                                    cursor: 'pointer',
                                    ...(opt?.ListItemProps?.sx || {}),
                                },
                                component: 'li',
                            },
                        };
                    })}
            />
        </>
    );
}
export default function SectionDrawer({
    handleLogout = () => {},
    window = undefined,
    open = false,
    toggleDrawer = () => {},
    handleLogin = () => {},
    ...otherProps
}) {
    const userInfo = useSelector((state) => state.userInfo.value);
    let {
        _id = null,
        username = '',
        gender = 'male',
        tel = '',
        rewardPoint = null,
    } = userInfo;

    const navigate = useNavigate();
    const location = useLocation().pathname;

    const container =
        window !== undefined ? () => window().document.body : undefined;
    const [isOpenCollapse, setIsOpenCollapse] = useState({
        drug: false,
        supplement: false,
        otherProduct: false,
        article: false,
        account: false,
        page: true,
        list: true,
        policy: false,
        contact: true,
    });
    const loginBox = (
        <Stack
            spacing={1.5}
            justifyContent="space-between"
            alignItems="flex-start"
            px={3}
            py={1}
            backgroundColor="rgba(0, 0, 139, 0.85)">
            <Stack spacing={0.5}>
                <Typography
                    variant="subtitle2"
                    sx={{ fontSize: 12, color: 'white' }}>
                    Bạn chưa đăng nhập.
                </Typography>
                <Typography
                    variant="subtitle2"
                    sx={{ fontSize: 12, color: 'white' }}>
                    Hãy đăng nhập để xem các khuyến mãi dành riêng cho thành
                    viên.
                </Typography>
            </Stack>
            <CustomButton
                id="drawer-login-button"
                variant="contained"
                color="success"
                disableEffect
                onClick={handleLogin}
                startIcon={<LoginIcon />}
                children="Đăng nhập"
            />
        </Stack>
    );
    const accountBox = (
        <Box
            sx={{
                backgroundColor: 'aliceblue',
                p: 2,
            }}>
            <Stack
                direction="row"
                alignItems="flex-start"
                justifyContent="space-between">
                <Stack direction="row" spacing={1} alignItems="flex-start">
                    <CustomAvatar size={35}>
                        {gender === 'male' ? (
                            <FaceIcon
                                sx={{
                                    p: 0.25,
                                    color: 'rgba(0, 0, 139, 0.75)',
                                    backgroundColor: 'papayawhip',
                                    border: '1px solid rgba(0, 0, 139, 0.75)',
                                    borderRadius: '50%',
                                }}
                                fontSize="large"
                            />
                        ) : (
                            <Face2Icon
                                sx={{
                                    p: 0.25,
                                    color: 'rgba(0, 0, 139, 0.75)',
                                    backgroundColor: 'papayawhip',
                                    border: '1px solid rgba(0, 0, 139, 0.75)',
                                    borderRadius: '50%',
                                }}
                                fontSize="large"
                            />
                        )}
                    </CustomAvatar>
                    <Box>
                        <Typography
                            noWrap
                            variant="subtitle2"
                            sx={{
                                fontSize: 16,
                                color: 'darkblue',
                                maxWidth: 160,
                                lineHeight: 0.8,
                            }}>
                            {username}
                        </Typography>
                        <Typography variant="caption">{tel}</Typography>
                    </Box>
                </Stack>
                <CustomButton
                    id="drawer-logout-button"
                    variant="contained"
                    disableEffect
                    styleProps={{
                        width: 'fit-content',
                        px: 1,
                        py: 0.5,
                        borderRadius: '20px',
                    }}
                    onClick={() => handleLogout()}
                    children="Đăng xuất"
                />
            </Stack>
            {rewardPoint && (
                <Stack direction="row" spacing={1} alignItems="center">
                    <EmojiEventsIcon sx={{ color: 'orange' }} />
                    <Typography sx={{ color: 'darkblue' }}>
                        {'Điểm tích lũy: '}
                        {Number(rewardPoint).toLocaleString() + ' điểm'}
                    </Typography>
                </Stack>
            )}
        </Box>
    );

    return (
        <Box component="nav">
            <Drawer
                container={container}
                variant="temporary"
                open={open}
                onClose={toggleDrawer}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: drawerWidth,
                        ...customScrollbar,
                    },
                }}>
                <Box
                    sx={{
                        height: '100vh',
                    }}>
                    <Stack justifyContent="space-between" height="100%">
                        <Box>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{ p: 0.5 }}>
                                <LogoBrand
                                    styleProps={{ marginLeft: '-14px' }}
                                    width="160px"
                                    height="60px"
                                />
                                <CustomButton
                                    id="drawer-close-button"
                                    useIconButton
                                    onClick={toggleDrawer}
                                    children={<CloseIcon />}
                                />
                            </Stack>
                            {_id ? accountBox : loginBox}
                            <List sx={{ mb: 1 }}>
                                {[
                                    {
                                        name: 'drug',
                                        mainSlug: '/thuoc',
                                        primaryText: 'Thuốc',
                                        options: cateLv2
                                            .filter(
                                                (i) => i.parentName === 'Thuốc'
                                            )
                                            .map((i) => '/thuoc' + i.slug),
                                    },
                                    {
                                        name: 'supplement',
                                        mainSlug: '/thuc-pham-chuc-nang',
                                        primaryText: 'Thực phẩm chức năng',
                                        options: cateLv2
                                            .filter(
                                                (i) =>
                                                    i.parentName ===
                                                    'Thực phẩm chức năng'
                                            )
                                            .map(
                                                (i) =>
                                                    '/thuc-pham-chuc-nang' +
                                                    i.slug
                                            )
                                            .sort((a, b) => a.localeCompare(b)),
                                    },
                                    {
                                        name: 'otherProduct',
                                        mainSlug: '/san-pham-khac',
                                        primaryText: 'Sản phẩm khác',
                                        options: cateLv2
                                            .filter(
                                                (i) =>
                                                    i.parentName ===
                                                    'Sản phẩm khác'
                                            )
                                            .map(
                                                (i) => '/san-pham-khac' + i.slug
                                            ),
                                    },
                                    {
                                        name: 'article',
                                        mainSlug: '/bai-viet-suc-khoe',
                                        primaryText: 'Bài viết sức khỏe',
                                        options: articleLv1.map(
                                            (i) => '/bai-viet-suc-khoe' + i.slug
                                        ),
                                    },
                                    // {
                                    //     name: 'account',
                                    //     primaryText: 'Tài khoản',
                                    //     options: [
                                    //         '/thong-tin-tai-khoan',
                                    //         '/lich-su-mua-hang',
                                    //         '/dia-chi-nhan-hang',
                                    //     ],
                                    // },
                                    {
                                        name: 'page',
                                        mainSlug: '/admin',
                                        primaryText: 'Quản lý Page',
                                        options: [
                                            '/admin/homepage',
                                            '/admin/market',
                                            '/admin/product-item',
                                            '/admin/manufacture',
                                            '/admin/ingredient',
                                            '/admin/article-item',
                                            '/admin/keyword',
                                        ],
                                    },
                                    {
                                        name: 'list',
                                        mainSlug: '/admin',
                                        primaryText: 'Danh sách',
                                        options: [
                                            '/admin/staff',
                                            '/admin/commission-products',
                                            '/admin/salary',
                                        ],
                                    },
                                    {
                                        name: 'policy',
                                        mainSlug: '',
                                        primaryText: 'Quy chế hoạt động',
                                        options: [
                                            '/khach-hang-than-thiet',
                                            '/quy-che-hoat-dong',
                                            '/chinh-sach-giao-hang',
                                            '/chinh-sach-thanh-toan',
                                            '/chinh-sach-doi-tra-hang',
                                            '/chinh-sach-bao-mat',
                                            '/chinh-sach-thu-thap-va-xu-ly-du-lieu',
                                        ],
                                    },
                                    {
                                        name: 'contact',
                                        mainSlug: null,
                                        primaryText: 'Liên hệ',
                                        options: contactOptions,
                                    },
                                ]
                                    .filter((i) =>
                                        location.startsWith('/admin')
                                            ? i
                                            : i.name !== 'list' &&
                                              i.name !== 'page'
                                    )
                                    .map(
                                        (
                                            {
                                                name,
                                                mainSlug,
                                                primaryText,
                                                options,
                                            },
                                            idx
                                        ) => {
                                            let onCollapse = () => {
                                                setIsOpenCollapse(
                                                    (prevValue) => {
                                                        let updatedValue = {
                                                            ...prevValue,
                                                        };
                                                        updatedValue[name] =
                                                            !updatedValue[name];
                                                        return updatedValue;
                                                    }
                                                );
                                            };
                                            return (
                                                <Fragment key={primaryText}>
                                                    <CustomCollapse
                                                        primaryText={
                                                            primaryText
                                                        }
                                                        open={
                                                            isOpenCollapse[name]
                                                        }
                                                        mainSlug={mainSlug}
                                                        onNavigate={() => {
                                                            if (
                                                                [
                                                                    'drug',
                                                                    'supplement',
                                                                    'otherProduct',
                                                                    'article',
                                                                ].includes(name)
                                                            ) {
                                                                navigate(
                                                                    mainSlug
                                                                );
                                                                if (
                                                                    !isOpenCollapse[
                                                                        name
                                                                    ]
                                                                ) {
                                                                    onCollapse();
                                                                }
                                                                toggleDrawer();
                                                            } else {
                                                                onCollapse();
                                                            }
                                                        }}
                                                        onCollapse={onCollapse}
                                                        options={options}
                                                        toggleDrawer={
                                                            toggleDrawer
                                                        }
                                                    />
                                                </Fragment>
                                            );
                                        }
                                    )}
                            </List>
                        </Box>
                        <Box sx={quoteCSS}>
                            <Box sx={{ width: '70%', ml: 'auto' }}>
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        fontWeight: 600,
                                        textAlign: 'center',
                                    }}>
                                    {'Hotline Zalo hỗ trợ'}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        textAlign: 'center',
                                    }}>
                                    {'(08:00 - 21:30)'}
                                </Typography>
                                <div
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                    }}>
                                    <Typography
                                        component="a"
                                        href="tel:0704430919"
                                        sx={{
                                            fontSize: 18,
                                            fontWeight: 600,
                                            pt: 0.5,
                                            '&:hover': {
                                                color: 'blue',
                                                backgroundColor: 'transparent',
                                            },
                                            textDecoration: 'none',
                                            color: 'blue',
                                        }}>
                                        070.443.0919
                                    </Typography>
                                </div>
                            </Box>
                        </Box>
                    </Stack>
                </Box>
            </Drawer>
        </Box>
    );
}
const quoteCSS = {
    backgroundImage: `url('../assets/images/pharmacy-illustration.png')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '120px 90px',
    backgroundPosition: 'left',
    ':hover': {
        backgroundColor: '#EDEDED',
    },
    py: 2,
    border: 'none',
    padding: '20px',
    backgroundColor: '#EDEDED',
    width: '100%',
    position: 'relative',
    filter: 'drop-shadow(0px 0px 1px rgba(0,0,0,0.32))',
    '::before': {
        content: '"\\201C"',
        position: 'absolute',
        left: '10px',
        top: '-5px',
        fontSize: '35px',
        height: '35px',
        fontFamily: 'Georgia',
        fontWeight: 'bold',
        color: 'darkblue',
    },
    '::after': {
        content: '"\\201D"',
        position: 'absolute',
        right: '10px',
        top: '-5px',
        fontSize: '35px',
        height: '35px',
        fontFamily: 'Georgia',
        fontWeight: 'bold',
        color: 'darkblue',
    },
};
const iconProps = {
    fontSize: 'small',
    sx: { mt: -0.25 },
};
const iconMapping = {
    '/thong-tin-tai-khoan': <PersonIcon {...iconProps} />,
    '/lich-su-mua-hang': <ShoppingBasketIcon {...iconProps} />,
    '/dia-chi-nhan-hang': <PinDropIcon {...iconProps} />,
    '/admin/homepage': <HomeIcon {...iconProps} />,
    '/admin/market': <StorefrontIcon {...iconProps} />,
    '/admin/product-item': <TopicIcon {...iconProps} />,
    '/admin/manufacture': <FactoryIcon {...iconProps} />,
    '/admin/ingredient': <MedicationLiquidIcon {...iconProps} />,
    '/admin/article-item': <NewspaperIcon {...iconProps} />,
    '/admin/keyword': <KeyIcon {...iconProps} />,
    '/admin/staff': <GroupsRoundedIcon {...iconProps} />,
    '/admin/commission-products': <CollectionsBookmarkIcon {...iconProps} />,
    '/admin/salary': <PaymentsIcon {...iconProps} />,
};
const contactOptions = [
    {
        icon: <WhatsAppIcon fontSize="small" sx={{ mt: -0.2 }} />,
        children: (
            <ListItemText
                primary={
                    <div>
                        <Typography
                            component="a"
                            href="tel:0704430919"
                            sx={{
                                fontSize: 14,
                                fontWeight: 600,
                                '&:hover': {
                                    color: 'blue',
                                    backgroundColor: 'transparent',
                                },
                                textDecoration: 'none',
                                color: 'blue',
                            }}>
                            070.443.0919
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: 600,
                                color: 'rgba(0,0,0,0.6)',
                            }}>
                            (Zalo | 08:00 - 21:30)
                        </Typography>
                    </div>
                }
            />
        ),
        ListItemProps: { sx: { padding: '8px 16px !important' } },
    },
    {
        icon: <ScheduleIcon fontSize="small" />,
        children: (
            <ListItemText
                primary={
                    <Typography sx={{ fontSize: 14 }}>
                        Thứ 2 - Chủ nhật | 06:30 - 22:15
                    </Typography>
                }
            />
        ),
        ListItemProps: { sx: { padding: '8px 16px !important' } },
    },
    {
        icon: <PinDropIcon fontSize="small" />,
        children: (
            <ListItemText
                primary={
                    <Typography
                        aria-label="googlemap-icon"
                        component="a"
                        href="https://www.google.com/maps/place/Nh%C3%A0+Thu%E1%BB%91c+T%C6%B0+Nh%C3%A2n+Kh%C3%A1nh+Trang/@10.8158692,106.5945157,16.77z/data=!4m6!3m5!1s0x31752b97cd17fb6f:0xb3ee2a6fe105213!8m2!3d10.8158685!4d106.5966005!16s%2Fg%2F11xhbyt7_"
                        sx={{
                            fontSize: 14,
                            '&:hover': {
                                color: 'blue',
                                backgroundColor: 'transparent',
                            },
                            textDecoration: 'none',
                            color: 'black',
                            maxWidth: '95%',
                        }}>
                        124 Nguyễn Thị Tú, P. Bình Hưng Hoà B, Q. Bình Tân, TP.
                        HCM
                    </Typography>
                }
            />
        ),
        ListItemProps: { sx: { padding: '8px 16px !important' } },
    },
    {
        icon: <ShareIcon fontSize="small" />,
        children: (
            <ListItemText
                primary={
                    <Typography sx={{ fontSize: 14 }}>
                        Xem các bài chia sẻ hay về thuốc và bệnh tại{' '}
                        <CustomButton
                            useIconButton
                            aria-label="facebook-icon"
                            component="a"
                            href="https://www.facebook.com/nhathuockhanhtrang/"
                            children={
                                <FacebookIcon
                                    fontSize="small"
                                    sx={{
                                        color: '#4267b2',
                                        '&:hover': {
                                            color: '#4267b2',
                                        },
                                    }}
                                />
                            }
                        />
                        <CustomButton
                            useIconButton
                            aria-label="youtube-icon"
                            component="a"
                            href="https://www.youtube.com/@duocsitantam124/about"
                            children={
                                <YouTubeIcon
                                    fontSize="small"
                                    sx={{
                                        color: 'red',
                                        '&:hover': {
                                            color: 'red',
                                        },
                                    }}
                                />
                            }
                        />
                    </Typography>
                }
            />
        ),
        ListItemProps: { sx: { padding: '8px 16px !important' } },
    },
];
