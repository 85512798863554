import PropTypes from 'prop-types';

import { Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import useCustomColor from 'hooks/useCustomColor';
import { useMemo } from 'react';

/**
 * CustomChip Component
 * @param {Object} props
 * @param {string | React.ReactNode} props.label - Required label for the chip.
 * @param {'filled' | 'outlined'} [props.variant='outlined'] - The variant of the chip.
 * @param {'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'} [props.color='primary'] - The color of the chip.
 * @param {'small' | 'medium'} [props.size='small'] - The size of the chip.
 * @param {boolean} [props.disabled=false] - If true, the chip will be disabled.
 * @param {boolean} [props.clickable=true] - If true, the chip will be clickable.
 * @param {function} [props.onClick] - Function to call on click.
 * @param {React.CSSProperties} [props.styleProps] - Custom styles for the chip.
 * @returns {JSX.Element}
 */

function CustomChip({
    label,
    variant = 'outlined',
    color = 'primary',
    size = 'small',
    disabled = false,
    avatar = undefined,
    icon = undefined,
    deleteIcon = undefined,
    clickable = true,
    onClick = undefined,
    onDelete = undefined,
    styleProps = {},
    skipFocusWhenDisabled = true,
    ...otherProps
}) {
    const theme = useTheme();
    let customPalleteColor = useCustomColor(color);

    let chipStyles = useMemo(() => {
        let { main, light, lighter, contrastText } = customPalleteColor;

        let darkDisabledColor = theme.palette['disabled'].dark;
        let mainDisabledColor = theme.palette['disabled'].main;
        let lightDisabledColor = theme.palette['disabled'].light;
        let css = {
            filled: {
                '&.MuiChip-root': {
                    backgroundColor: main,
                    color: contrastText,
                    '& .MuiChip-icon': { color: contrastText },
                    '& .MuiChip-deleteIcon': { color: contrastText },
                    ':hover': {
                        backgroundColor: clickable ? light : main,
                        color: contrastText,
                    },
                    '&.Mui-disabled': {
                        color: darkDisabledColor,
                        backgroundColor: lightDisabledColor,
                        '& .MuiChip-icon': { color: mainDisabledColor },
                        '& .MuiChip-deleteIcon': { color: mainDisabledColor },
                    },
                    ...styleProps,
                },
            },
            outlined: {
                '&.MuiChip-root': {
                    backgroundColor: 'transparent',
                    borderColor: light,
                    color: main,
                    '& .MuiChip-icon': { color: main },
                    '& .MuiChip-deleteIcon': { color: main },
                    ':hover': {
                        color: main,
                        backgroundColor: clickable ? lighter : 'transparent',
                        borderColor: light,
                    },
                    '&.Mui-disabled': {
                        color: darkDisabledColor,
                        backgroundColor: 'transparent',
                        borderColor: lightDisabledColor,
                        '& .MuiChip-icon': { color: mainDisabledColor },
                        '& .MuiChip-deleteIcon': { color: mainDisabledColor },
                    },
                    ...styleProps,
                },
            },
        };

        return css[variant];
    }, [clickable, customPalleteColor, styleProps, theme.palette, variant]);

    let chipProps = {
        skipFocusWhenDisabled,
        label,
        variant,
        disabled,
        avatar,
        icon,
        deleteIcon,
        size,
        onClick,
        onDelete,
        clickable,
    };
    if (!label) return null;

    return <Chip {...chipProps} sx={chipStyles} {...otherProps} />;
}

CustomChip.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    variant: PropTypes.oneOf(['filled', 'outlined']),
    color: PropTypes.oneOfType([
        PropTypes.oneOf([
            'primary',
            'secondary',
            'success',
            'error',
            'info',
            'warning',
            'disabled',
        ]),
        PropTypes.string, // For custom colors
    ]),
    size: PropTypes.oneOf(['small', 'medium']),
    disabled: PropTypes.bool,
    clickable: PropTypes.bool,
    onClick: PropTypes.func,
    styleProps: PropTypes.object,
};

export default CustomChip;
