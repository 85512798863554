import { Stack, Typography, Box } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { CustomChip, CustomButton } from 'UI';
import { gridTabIndexColumnHeaderFilterSelector } from '@mui/x-data-grid';

export default function ActiveFilterBox({
    activeFilterCount = 0,
    dataFilter = {},
    resetFilter = () => {},
    ...otherProps
}) {
    if (activeFilterCount === 0) {
        return null;
    }
    let chips = Object.keys(dataFilter).reduce((acc, key) => {
        acc = acc.concat(
            dataFilter[key].checkList.map((chip, idx) => {
                return (
                    <CustomChip
                        key={`${chip}-${idx}`}
                        color="disabled"
                        clickable={false}
                        label={chip}
                        styleProps={{
                            my: 0.5,
                        }}
                        deleteIcon={<CloseIcon />}
                        onDelete={() => {
                            dataFilter[key].onCheck((data) =>
                                data.filter((i) => i !== chip)
                            );
                        }}
                    />
                );
            })
        );
        return acc;
    }, []);
    return (
        <Box sx={{ width: '100%', px: 2 }}>
            <Stack
                sx={{
                    backgroundColor: 'white',
                    p: 1,
                    borderTop: { xs: '1px solid #EDEDED', lg: 'none' },
                    borderRadius: {
                        xs: '0px 0px 10px 10px',
                        lg: '10px',
                    },
                }}
                direction="row"
                flexWrap="wrap"
                alignItems="center"
                spacing={1}>
                <Typography>{`Lọc theo (${activeFilterCount})`}</Typography>
                {chips}
                <CustomButton
                    id="clear-all-filter-custom-button"
                    varinat="contained"
                    disableEffect
                    onClick={resetFilter}
                    children={<b>Xóa tất cả</b>}
                />
            </Stack>
        </Box>
    );
}
