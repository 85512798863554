import { useState, forwardRef } from 'react';

import {
    Typography,
    Stack,
    useMediaQuery,
    Box,
    Popper,
    Paper,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
    Search as SearchIcon,
    History as HistoryIcon,
    Close as CloseIcon,
    ArrowBackIosNew as ArrowBackIosNewIcon,
} from '@mui/icons-material';

import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { searchHistoryActions } from 'store/searchHistory-slice.js';
import { systemActions } from 'store/system-slice.js';

import {
    CustomButton,
    CustomSearch,
    CustomChip,
    CustomList,
    CustomHighlightText,
} from 'UI';

function CustomPopper(props) {
    return <Popper {...props} placement="bottom-end" />;
}
function CustomPaper({ children, ...props }) {
    return (
        <Paper
            {...props}
            sx={{
                '& .MuiAutocomplete-noOptions': {
                    px: 0,
                },
                position: 'absolute',
                right: { xs: -25, sm: -5 },
                marginTop: '18px',
                borderRadius: { xs: 0, sm: '15px' },
                border: { xs: 'none', sm: '1px solid rgba(0, 0, 139, 0.75)' },
                height: { xs: 'calc(100vh - 70px)', sm: 'auto' },
                width: {
                    xs: '100vw',
                    sm: 'calc(100% + 20px)',
                },
            }}>
            {children}
        </Paper>
    );
}
const CustomListbox = forwardRef(function CustomListbox(
    { children, ...props },
    ref
) {
    return (
        <Box
            component={'ul'}
            ref={ref}
            {...props}
            sx={{
                maxHeight: { xs: '100vh', sm: '60vh' },
                padding: '0px !important',
            }}>
            {children}
        </Box>
    );
});

export default function SearchAppbar({
    open = false,
    onOpen = () => {},
    ...props
}) {
    const dispatch = useDispatch();
    const searchHistory = useSelector((state) => state.searchHistory);

    const navigate = useNavigate();
    const theme = useTheme();
    const biggerSM = useMediaQuery(theme.breakpoints.up('sm')); // >= 600
    const biggerMD = useMediaQuery(theme.breakpoints.up('md')); // >= 800

    const [searchValue, setSearchValue] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const handleDeleteItemSearchHistory = (searchTerm) => {
        dispatch(searchHistoryActions.deleteItemSearchHistory(searchTerm));
    };
    const handleClearSearchHistory = () => {
        dispatch(searchHistoryActions.clearSearchHistory());
    };
    const handleAddSearchHistory = (searchTerm) => {
        dispatch(searchHistoryActions.addSearchHistory(searchTerm));
    };
    const handleOpen = () => {
        onOpen(true);
        dispatch(systemActions.toggleDimBackground(true));
        document.body.style.overflow = 'hidden';
    };
    const handleClose = (event, reason) => {
        if (!biggerSM && reason !== 'selectOption') {
            return;
        }
        onOpen(false);
        dispatch(systemActions.toggleDimBackground(false));
        document.body.style.overflow = '';
    };

    const handleSearch = (searchTerm) => {
        if (searchTerm) {
            handleAddSearchHistory(searchTerm);
            navigate('/tim-kiem?s=' + searchTerm);
            setSearchValue(null);
        }
    };
    const handleChange = (newValue, reason) => {
        if (newValue) {
            // save to localStorage when clicking keyword
            if (newValue.type === 'keyword' && newValue.value) {
                handleSearch(newValue.value);
                // go to page /tim-kiem and render with searchTerm = newValue.value
            } else if (
                newValue.type === 'product-item' &&
                reason === 'selectOption'
            ) {
                navigate(`/san-pham${newValue.slug}`);
                handleClose(null, 'selectOption');
                // go to exact Page product item by slug from obj newValue
            }
        }
    };
    let suggestion = (
        <Stack alignItems="flex-start" spacing={1.5}>
            {searchHistory.length > 0 && (
                <Stack alignItems="flex-start" width="100%">
                    <Stack
                        px={2.5}
                        width="100%"
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between">
                        <Typography sx={{ fontWeight: 600 }}>
                            Lịch sử tìm kiếm
                        </Typography>
                        <CustomButton
                            id="clear-all-search-history-custom-button"
                            styleProps={{ borderRadius: '20px', px: 1 }}
                            onClick={handleClearSearchHistory}
                            children="Xóa tất cả"
                        />
                    </Stack>
                    <CustomList
                        id="searchHistory-listId"
                        ListProps={{ sx: { width: '100%', py: 0 } }}
                        ListItemProps={{
                            sx: {
                                '&.MuiListItem-root': {
                                    p: '4px 12px',
                                    cursor: 'pointer',
                                    ':hover': {
                                        backgroundColor: '#EDEDED',
                                    },
                                },
                            },
                        }}
                        items={searchHistory.slice(0, 5).map((term, idx) => ({
                            name: term + idx,
                            label: null,
                            icon: (
                                <HistoryIcon
                                    fontSize="small"
                                    onClick={() => {
                                        handleSearch(term);
                                        handleClose(null, 'selectOption');
                                    }}
                                    sx={{
                                        color: 'blue',
                                        opacity: 0.8,
                                    }}
                                />
                            ),
                            subLabel: (
                                <Stack
                                    width="100%"
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent="space-between">
                                    <Typography
                                        onClick={() => {
                                            handleSearch(term);
                                            handleClose(null, 'selectOption');
                                        }}
                                        sx={{
                                            color: 'blue',
                                            opacity: 0.8,
                                            width: '100%',
                                        }}>
                                        {term}
                                    </Typography>
                                    <CustomButton
                                        id={`${term}-${idx}-clear-button`}
                                        useIconButton
                                        color="disabled"
                                        onClick={() =>
                                            handleDeleteItemSearchHistory(term)
                                        }
                                        children={
                                            <CloseIcon fontSize="small" />
                                        }
                                    />
                                </Stack>
                            ),
                        }))}
                    />
                </Stack>
            )}
            <Stack alignItems="flex-start" spacing={1.5} px={1}>
                <Typography sx={{ fontWeight: 600, px: 1.5 }}>
                    Tra cứu hàng đầu
                </Typography>
                <Stack
                    direction="row"
                    flexWrap="wrap"
                    alignItems="center"
                    spacing={1}>
                    {[
                        'dung dịch vệ sinh',
                        'men vi sinh',
                        'sữa rửa mặt',
                        'siro tăng đề kháng',
                        'kem chống nắng',
                        'D3k2',
                        'canxi',
                    ].map((chip, idx) => {
                        return (
                            <CustomChip
                                key={chip}
                                label={chip}
                                color="disabled"
                                styleProps={{
                                    my: 0.5,
                                    ml: idx === 0 ? 1.5 : 1,
                                }}
                                onClick={() => {
                                    handleSearch(chip);
                                    handleClose(null, 'selectOption');
                                }}
                            />
                        );
                    })}
                </Stack>
            </Stack>
        </Stack>
    );
    let convertArrOptions = (options) => {
        return options
            .map(({ value, type }) => {
                if (type === 'product-item') {
                    return {
                        type,
                        id: value._id,
                        value: (
                            <Stack
                                justifyContent="flex-start"
                                width="100%"
                                p={1}>
                                <CustomHighlightText
                                    label={value.shortName}
                                    keyword={searchTerm}
                                />
                                <Stack
                                    mt={1}
                                    alignItems="flex-start"
                                    spacing={0.25}>
                                    <Typography sx={{ fontSize: 14 }}>
                                        {value.webName}
                                    </Typography>
                                    {value.prescription && (
                                        <Typography
                                            variant="subttile2"
                                            sx={{ color: 'grey' }}>
                                            Cần tư vấn của dược sỹ
                                        </Typography>
                                    )}
                                    <Typography sx={{ fontSize: 12 }}>
                                        {
                                            value.category[
                                                value.category.length - 1
                                            ].name
                                        }
                                    </Typography>
                                </Stack>
                            </Stack>
                        ),
                        avatar:
                            value.primaryImage.mainSrc ||
                            '../assets/images/khanhtranglogo-02.png',
                        ...value,
                    };
                } else if (type === 'keyword') {
                    return {
                        type,
                        id: value,
                        value: (
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                                py={0.5}>
                                <SearchIcon
                                    fontSize="small"
                                    sx={{ color: 'blue', opacity: 0.75 }}
                                />
                                <CustomHighlightText
                                    label={value}
                                    keyword={searchTerm}
                                />
                            </Stack>
                        ),
                    };
                } else if (type === 'see-more') {
                    return {
                        type,
                        id: value,
                        value: (
                            <CustomButton
                                id="see-all-results-button"
                                disableEffect
                                fullWidth
                                styleProps={{
                                    fontSize: 14,
                                    fontWeight: 500,
                                    textAlign: 'center',
                                }}
                                endIcon={
                                    <ArrowBackIosNewIcon
                                        sx={{
                                            transform: 'rotate(180deg)',
                                            width: 14,
                                            height: 14,
                                        }}
                                    />
                                }
                                onClick={() =>
                                    handleChange({
                                        type: 'keyword',
                                        value: searchTerm,
                                    })
                                }
                                children="Xem tất cả"
                            />
                        ),
                    };
                } else return null;
            })
            .filter((i) => i);
    };
    return (
        <CustomSearch
            id="page-appbar-custom-search" // important to render width of paper
            name="appbar"
            pathname="/search/suggest"
            {...configProps}
            value={searchValue}
            getInputValue={(inputValue) => {
                setSearchTerm(inputValue);
            }}
            onChange={handleChange}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    handleSearch(searchTerm);
                    handleClose(null, 'selectOption');
                }
            }}
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            convertArrOptions={convertArrOptions}
            initialText={suggestion}
            margin={biggerMD ? 'dense' : 'none'}
            size={biggerMD ? 'medium' : 'small'}
            styleProps={{
                border: '1px solid rgba(0, 0, 139, 0.75)',
                borderRadius: '30px !important',
                padding: biggerMD ? '10px 8px 10px 14px' : '4px 8px 4px 14px',
                backgroundColor: 'white',
                '& .MuiInputBase-root': {
                    mt: 0,
                },
                '& .MuiInput-root': {
                    '::before': { content: 'none' },
                    '::after': { borderBottom: 'none' },
                },
                '& .MuiAutocomplete-popupIndicator': {
                    display: 'none',
                },
            }}
            endAdornment={
                <CustomButton
                    id="search-button"
                    onClick={() => {
                        if (searchTerm) {
                            handleSearch(searchTerm);
                            handleClose(null, 'selectOption');
                        } else {
                            if (open) {
                                handleClose(null, 'selectOption');
                            } else {
                                handleOpen();
                            }
                        }
                    }}
                    useIconButton
                    styleProps={{ p: 0, mr: -4 }}
                    children={
                        <SearchIcon
                            sx={{
                                width: 32,
                                height: 32,
                                backgroundColor: 'rgba(0, 0, 139, 0.75)',
                                borderRadius: '50%',
                                p: 0.75,
                                color: 'white',
                            }}
                        />
                    }
                />
            }
            PopperComponent={CustomPopper}
            PaperComponent={CustomPaper}
            ListboxComponent={CustomListbox}
        />
    );
}

let configProps = {
    groupBy: (option) => option.type,
    renderGroup: (params) => {
        return (
            <li key={params.key}>
                {params.key !== 0 && (
                    <Box
                        sx={{
                            mx: 'auto',
                            width: '98%',
                            height: '1px',
                            backgroundColor: 'lightgrey',
                        }}></Box>
                )}
                <ul style={{ padding: 0 }}>{params.children}</ul>
            </li>
        );
    },
    getOptionKey: (option) => Math.random() * 1000,
    placeholder: 'Tìm tên thuốc, thực phẩm chức năng...',
    clearOnBlur: false,
    disableClearable: true,
    openOnFocus: false,
    autoHighlight: false,
    filterOptions: (x) => x,
};
