import { Breadcrumbs, Link, Typography } from '@mui/material';

import { Link as RouterLink } from 'react-router-dom';

export default function SectionBreadcrumbs({ breadcrumbsData = [], ...props }) {
    return (
        <div role="presentation">
            <Breadcrumbs
                sx={{
                    '.MuiBreadcrumbs-li:last-of-type': {
                        flex: 1,
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        '.MuiTypography-root': {
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                        },
                    },
                }}
                aria-label="breadcrumb">
                <Link
                    underline="none"
                    component={RouterLink}
                    sx={{
                        fontSize: '14px',
                        color: 'blue',
                    }}
                    to="/">
                    Trang chủ
                </Link>
                {breadcrumbsData.map(
                    ({ to = '/', name = '', disabled = false }) => {
                        let ComponentName = disabled ? Typography : Link;
                        let componentVariant = disabled ? 'span' : RouterLink;
                        return (
                            <ComponentName
                                key={'breadcrumb-' + Math.random() * 1000}
                                underline="none"
                                component={componentVariant}
                                sx={{
                                    fontSize: '14px',
                                    color: disabled ? 'black' : 'blue',
                                }}
                                to={disabled ? 'undefined' : to}>
                                {name}
                            </ComponentName>
                        );
                    }
                )}
            </Breadcrumbs>
        </div>
    );
}
