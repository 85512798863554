import { useState, useEffect, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Stack, Typography, Autocomplete, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { darken } from '@mui/system';
import { Add as AddIcon } from '@mui/icons-material';

import { CustomButton, CustomChip } from 'UI';
import { OptionRow } from 'template';

import useCustomColor from 'hooks/useCustomColor';
import useDebounce from 'hooks/useDebounce.js';

/**
 * Styled input component that supports different variants: 'standard', 'outlined', and 'filled'.
 */
const StyledTextField = styled(TextField)(({ theme, ...props }) => {
    let { main, lighter } = useCustomColor(
        props.color
    );
    let { styleProps } = props;
    return {
        '&.MuiTextField-root ': {
            height: '100%',
            ...(props.variant === 'standard' && {
                '& .MuiInputBase-root': {
                    color: main,
                    height: '100%',
                    alignItems: 'flex-start',
                    ':hover:not(.Mui-disabled, .Mui-error):before': {
                        borderWidth: '1px',
                        borderColor: main,
                    },
                    '::after': {
                        borderBottom: `2px solid ${main}`,
                    },
                },
            }),
            ...(props.variant === 'filled' && {
                '& .MuiFilledInput-root': {
                    backgroundColor: lighter,
                    color: theme.palette['disabled'].main,
                    height: '100%',
                    alignItems: 'flex-start',
                    ':hover:not(.Mui-disabled, .Mui-focused)': {
                        backgroundColor: darken(lighter, 0.03),
                        color: theme.palette['disabled'].main,
                    },
                    '.Mui-disabled': {
                        backgroundColor: theme.palette['disabled'].lighter,
                    },
                },
            }),
            ...(props.variant === 'outlined' && {
                '& .MuiOutlinedInput-root': {
                    color: main,
                    height: '100%',
                    alignItems: 'center',
                    '&:hover:not(.Mui-disabled, .Mui-error) fieldset': {
                        borderColor: main,
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: main,
                    },
                    '&.Mui-error fieldset': {
                        borderColor: theme.palette.error.main,
                    },
                    '&.Mui-disabled fieldset': {
                        borderColor: theme.palette.disabled.light,
                    },
                },
            }),
            ...styleProps,
        },
        ...(props.type === 'number' && {
            '& input[type=number]::-webkit-inner-spin-button': {
                WebkitAppearance: 'none',
                margin: 0,
                display: 'none',
            },
            '& input[type=number]::-webkit-outer-spin-button ': {
                WebkitAppearance: 'none',
                margin: 0,
                display: 'none',
            },
        }),
    };
});

/**
 * Renders a customized combo box with autocomplete options, custom styling, and an optional add-new option.
 *
 * @component
 * @param {Object} props - Component props
 * @param {string} [props.name='name'] - Name of the input field
 * @param {string} [props.id] - Unique ID for the component
 * @param {string} [props.type='text'] - Input type, defaults to 'text'
 * @param {Array} props.options - Array of options to display in the autocomplete
 * @param {function} props.convertArrOptions - Function to convert raw options to expected structure {id (must be unique for option), value (render as label of OptionRow, use to filter with userInput), subLabel (optional), avatar (optional), icon (optional)...}
 * @param {Object} [props.value] - Current selected value(s) (controlled component)
 * @param {function} props.onChange - Callback when selected value changes
 * @param {string} [props.label=''] - Label for the input field
 * @param {string} [props.error=''] - Error message for input field
 * @param {string} [props.helperText=''] - Helper text for input field
 * @param {string} [props.placeholder=''] - Placeholder text
 * @param {Object} [props.inputProps={}] - Additional props for input
 * @param {Object} [props.InputLabelProps={ shrink: true }] - Props for input label
 * @param {Object} [props.FormHelperTextProps={}] - Props for form helper text
 * @param {boolean} [props.fullWidth=true] - Whether the input should take full width
 * @param {boolean} [props.required=false] - Whether the input is required
 * @param {boolean} [props.readOnly=false] - Whether the input is read-only
 * @param {boolean} [props.disabled=false] - Whether the input is disabled
 * @param {boolean} [props.multiple=true] - Whether multiple selections are allowed
 * @param {boolean} [props.disableCloseOnSelect=true] - Disables auto-closing on select
 * @param {boolean} [props.clearOnEscape=true] - Clears the input on escape key press
 * @param {string} [props.variant='standard'] - Input variant: 'filled', 'outlined', or 'standard'
 * @param {string} [props.size='small'] - Size of the input: 'small' or 'medium'
 * @param {string} [props.color='primary'] - Color of the input
 * @param {string} [props.margin='none'] - Margin style for the input
 * @param {number} [props.limitTags=10] - Max number of tags to display
 * @param {boolean} [props.displayHighlight=true] - Whether to highlight matching text
 * @param {boolean} [props.allowAddNewValue=false] - Enables add-new option
 * @param {function} [props.onAddNewValue] - Callback for adding new value
 * @param {function} [props.renderTemplateOption] - Function to render custom option template
 * @param {React.Node} [props.startAdornment] - Start adornment (e.g., icon)
 * @param {React.Node} [props.endAdornment] - End adornment (e.g., icon)
 * @param {Object} [props.styleProps={}] - Props for styling Textfield
 */
function CustomComboBox({
    name = 'name',
    id,
    type = 'text',
    options = [],
    convertArrOptions = (options) => options,
    multiple = true,
    value: valueProps = multiple ? [] : null,
    onChange = () => {},
    label = '',
    error = '',
    helperText = '',
    placeholder = '',

    inputProps = {},
    InputLabelProps = { shrink: true },
    FormHelperTextProps = {},

    fullWidth = true,
    required = false,
    readOnly = false,
    disabled = false,
    disableCloseOnSelect = true,
    clearOnEscape = true,

    variant = 'standard',
    size = 'small',
    color = 'primary',
    margin = 'none',
    limitTags = 10,

    displayHighlight = true,
    allowAddNewValue = false,
    onAddNewValue = () => {},
    renderTemplateOption = (props, option, { selected, inputValue }) => (
        <li {...props} style={{ padding: 0 }} key={props.id}>
            <OptionRow
                label={option.value}
                compareString={inputValue}
                selected={selected}
                displayCheckmark={true}
                checkboxProps={{
                    color: props.color,
                }}
                {...option}
            />
        </li>
    ),
    startAdornment = null,
    endAdornment = null,
    styleProps = {},
    ...otherProps
}) {
    const [inputValue, setInputValue] = useState('');
    const debouncedInputValue = useDebounce(inputValue, 300);

    useEffect(() => {
        setInputValue(valueProps?.value || '');
    }, [valueProps?.value]);

    const comboBoxId = useMemo(
        () => id || Math.random().toString(36).slice(2, 11),
        [id]
    );

    const noOptionsText = useMemo(() => {
        return debouncedInputValue.trim() ? (
            <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                width="100%"
                justifyContent={'space-between'}>
                <Typography>Không có kết quả phù hợp.</Typography>
                {allowAddNewValue && (
                    <CustomButton
                        id="add-new-data-custom-button"
                        variant={variant}
                        onClick={onAddNewValue}
                        startIcon={<AddIcon />}
                        children="Thêm"
                    />
                )}
            </Stack>
        ) : (
            <Typography>{placeholder}</Typography>
        );
    }, [
        allowAddNewValue,
        debouncedInputValue,
        onAddNewValue,
        placeholder,
        variant,
    ]);

    const defaultAutocompleteProps = {
        id: comboBoxId,
        label,
        size,
        disablePortal: true,
        clearOnBlur: true,
        autoHighlight: true,
        disableListWrap: true,
        disabled,
        readOnly,
        required,
        multiple,
        fullWidth,
        limitTags,
        disableCloseOnSelect,
        clearOnEscape,
        noOptionsText,
        openText: 'Mở',
        closeText: 'Đóng',
        clearText: 'Xóa',
    };
    const validatedOptions = useMemo(() => {
        const result = convertArrOptions(options);

        if (!Array.isArray(result)) {
            console.error('convertArrOptions must return an array');
            return [];
        }

        for (const option of result) {
            if (typeof option !== 'object' || !option.id || !option.value) {
                console.error(
                    "Each item returned by convertArrOptions must be an object with 'id' and 'value' properties"
                );
                return [];
            }
        }

        return result;
    }, [options, convertArrOptions]);

    const mergeAdornments = (...adornments) => {
        const nonNullAdornments = adornments.filter((el) => el != null);
        if (nonNullAdornments.length === 0) {
            return null;
        }

        if (nonNullAdornments.length === 1) {
            return nonNullAdornments[0];
        }

        return (
            <Stack direction="row">
                {nonNullAdornments.map((adornment, index) => (
                    <Fragment key={index}>{adornment}</Fragment>
                ))}
            </Stack>
        );
    };

    return (
        <Autocomplete
            {...defaultAutocompleteProps}
            getOptionLabel={(option) => option.value}
            isOptionEqualToValue={(option, value) =>
                option.value === value.value
            }
            options={validatedOptions}
            value={valueProps}
            onChange={(event, newValue) => {
                onChange(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => {
                params.InputProps.startAdornment = mergeAdornments(
                    startAdornment,
                    params.InputProps.startAdornment
                );
                params.InputProps.endAdornment = mergeAdornments(
                    endAdornment,
                    params.InputProps.endAdornment
                );
                let defaultInputProps = {
                    type,
                    label,
                    error: Boolean(error),
                    helperText: error || helperText,
                    placeholder,
                    slotProps: {
                        inputLabel: InputLabelProps,
                    },
                    fullWidth,
                    required,
                    variant,
                    color,
                    margin,
                    styleProps,
                };
                return <StyledTextField {...defaultInputProps} {...params} />;
            }}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <CustomChip
                        key={option.id}
                        size={size}
                        variant={variant !== 'standard' ? variant : 'outlined'}
                        color={color}
                        label={option?.chipLabel || option.value}
                        {...getTagProps({ index })}
                    />
                ))
            }
            renderOption={(props, option, { selected }) =>
                renderTemplateOption(props, option, {
                    selected,
                    inputValue: debouncedInputValue,
                })
            }
            {...otherProps}
        />
    );
}
CustomComboBox.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    type: PropTypes.string,
    options: PropTypes.array.isRequired,
    convertArrOptions: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onChange: PropTypes.func,
    label: PropTypes.string,
    error: PropTypes.string,
    helperText: PropTypes.string,
    placeholder: PropTypes.string,
    inputProps: PropTypes.object,
    InputLabelProps: PropTypes.object,
    FormHelperTextProps: PropTypes.object,
    fullWidth: PropTypes.bool,
    required: PropTypes.bool,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    multiple: PropTypes.bool,
    disableCloseOnSelect: PropTypes.bool,
    clearOnEscape: PropTypes.bool,
    variant: PropTypes.oneOf(['standard', 'filled', 'outlined']),
    size: PropTypes.oneOf(['small', 'medium']),
    color: PropTypes.oneOfType([
        PropTypes.oneOf([
            'primary',
            'secondary',
            'success',
            'error',
            'info',
            'warning',
            'disabled',
        ]),
        PropTypes.string, // For custom colors
    ]),
    margin: PropTypes.oneOf(['none', 'dense']),
    limitTags: PropTypes.number,
    displayHighlight: PropTypes.bool,
    allowAddNewValue: PropTypes.bool,
    onAddNewValue: PropTypes.func,
    renderTemplateOption: PropTypes.func,
    styleProps: PropTypes.object,
};

CustomComboBox.defaultProps = {
    name: 'name',
    type: 'text',
    options: [],
    convertArrOptions: (options) => options,
    onChange: () => {},
    label: '',
    error: '',
    helperText: '',
    placeholder: '',
    inputProps: {},
    InputLabelProps: { shrink: true },
    FormHelperTextProps: {},
    fullWidth: true,
    required: false,
    readOnly: false,
    disabled: false,
    multiple: true,
    disableCloseOnSelect: true,
    clearOnEscape: true,
    variant: 'standard',
    size: 'small',
    color: 'primary',
    margin: 'none',
    limitTags: 10,
    displayHighlight: true,
    allowAddNewValue: false,
    onAddNewValue: () => {},
    styleProps: {},
};

export default CustomComboBox;
