export const themeConfig = {
    palette: {
        contrastThreshold: 3.8,
        primary: {
            main: '#3843ad',
            dark: '#28318a',
            light: '#5e6ed6',
            lighter: '#d8daf0',
            contrastText: '#fff'
        },
        secondary: {
            main: '#00a5b4',
            dark: '#007783',
            light: '#33c7d5',
            lighter: '#d1f3f5',
            contrastText: '#000'
        },
        success: {
            main: '#2f7d32',
            dark: '#246024',
            light: '#5ba960',
            lighter: '#cfe9d0',
            contrastText: '#fff'
        },
        warning: {
            main: '#f37c04',
            dark: '#bf5d02',
            light: '#f9a53a',
            lighter: '#ffe3c2',
            contrastText: '#000'
        },
        info: {
            main: '#0188d1',
            dark: '#0167a3',
            light: '#4bbcf3',
            lighter: '#cfeeff',
            contrastText: '#fff'
        },
        error: {
            main: '#d32f2f',
            dark: '#992121',
            light: '#e57373',
            lighter: '#fcdcdc',
            contrastText: '#fff'
        },
        disabled: {
            main: '#3c3c3c',
            dark: '#000000',
            light: '#b5b5b5',
            lighter: '#f5f5f5',
            contrastText: '#fff'
        },
    },
    typography: {
        fontFamily: "'Inter', 'Roboto', Arial, sans-serif !important",
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 800,
            lg: 1000,
            xl: 1200,
            xxl: 1350,
        },
    },
    components: {
        MuiStack: {
            defaultProps: {
                direction: 'column',
                justifyContent: 'flex-start',
                alignItems: 'stretch',
                spacing: 0,
            },
        },
    },
};
