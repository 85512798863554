import {
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TableFooter,
} from '@mui/material';

export default function CustomTable({
    id, // required
    padding = 'normal', // accepted: 'checkbox', 'none', 'normal'
    size = 'medium', // accepted: 'medium', 'small'
    stickyHeader = false,
    TableProps = {},
    TableHeadProps = {},
    TableBodyProps = {},
    TableFoooterProps = {},
    heads = [], // required, input structure: [{id, label}]
    rows = [], // required, input structure: [{id, cells: [] // required input structure [{id, label},...]}, ...]
    footer = null,
    useStripedRows = true,
    ...props
}) {
    return (
        <Table
            size={size}
            padding={padding}
            stickyHeader={stickyHeader}
            aria-label={'custom-table-' + id}
            {...TableProps}>
            {heads.length > 0 && (
                <TableHead {...TableHeadProps}>
                    <TableRow
                        sx={{
                            backgroundColor: 'teal !important',
                            color: 'white !important',
                        }}
                        key={'table-row-' + Math.random() * 1000}>
                        {heads.map(
                            ({
                                id = Math.random() * 1000,
                                align: cellAlign = 'center', // accepted:  'inherit', 'left', 'center', 'right', 'justify'
                                padding: cellPadding = padding, // accepted: 'checkbox', 'none', 'normal'
                                cellSize = size, // accepted 'small', 'medium'
                                label: cellLabel = '',
                                ...cellProps // using for import directly props to TableCell like sx...
                            }) => {
                                return (
                                    <TableCell
                                        component="th"
                                        sx={{
                                            backgroundColor: 'inherit',
                                            color: 'inherit',
                                        }}
                                        key={'table-cell-' + id}
                                        {...([
                                            'inherit',
                                            'left',
                                            'center',
                                            'right',
                                            'justify',
                                        ].includes(cellAlign) && {
                                            align: cellAlign,
                                        })}
                                        {...([
                                            'checkbox',
                                            'none',
                                            'normal',
                                        ].includes(cellPadding) && {
                                            padding: cellPadding,
                                        })}
                                        {...(['small', 'medium'].includes(
                                            cellSize
                                        ) && {
                                            size: cellSize,
                                        })}
                                        {...cellProps}>
                                        {cellLabel}
                                    </TableCell>
                                );
                            }
                        )}
                    </TableRow>
                </TableHead>
            )}
            <TableBody {...TableBodyProps}>
                {rows.map((row) => {
                    const {
                        id = Math.random() * 1000,
                        cells,
                        styleProps = {},
                    } = row;
                    return (
                        <TableRow
                            key={'table-row-' + id}
                            sx={{
                                '&:nth-of-type(odd)': {
                                    backgroundColor: useStripedRows
                                        ? '#EDEDED'
                                        : 'inherit',
                                },
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                                ...styleProps,
                            }}>
                            {cells.map(
                                (
                                    {
                                        id = Math.random() * 1000,
                                        align: cellAlign = 'inherit', // accepted:  'inherit', 'left', 'center', 'right', 'justify'
                                        padding: cellPadding = padding, // accepted: 'checkbox', 'none', 'normal'
                                        size: cellSize = size, // accepted 'small', 'medium'
                                        label: cellLabel = '',
                                        ...cellProps // using for import directly props to TableCell like sx...
                                    },
                                    idx
                                ) => {
                                    return (
                                        <TableCell
                                            component={idx === 0 ? 'th' : 'td'}
                                            key={'table-cell-' + id}
                                            {...([
                                                'inherit',
                                                'left',
                                                'center',
                                                'right',
                                                'justify',
                                            ].includes(cellAlign) && {
                                                align: cellAlign,
                                            })}
                                            {...([
                                                'checkbox',
                                                'none',
                                                'normal',
                                            ].includes(cellPadding) && {
                                                padding: cellPadding,
                                            })}
                                            {...(['small', 'medium'].includes(
                                                cellSize
                                            ) && {
                                                size: cellSize,
                                            })}
                                            {...(idx === 0 && {
                                                scope: 'row',
                                            })}
                                            {...cellProps}>
                                            {cellLabel}
                                        </TableCell>
                                    );
                                }
                            )}
                        </TableRow>
                    );
                })}
            </TableBody>
            {footer && (
                <TableFooter {...TableFoooterProps}>
                    <TableRow key={'table-row-' + Math.random() * 1000}>
                        <TableCell colSpan={heads.length}>{footer}</TableCell>
                    </TableRow>
                </TableFooter>
            )}
        </Table>
    );
}
