import { Stack, Typography } from '@mui/material';

import { CustomAvatar } from 'UI';

export default function NoFoundData({ ...otherProps }) {
    return (
        <Stack
            spacing={3}
            justifyContent="center"
            alignItems="center"
            px={2}
            py={{ xs: 2, sm: 4 }}>
            <CustomAvatar
                src="../assets/images/pharmacy-illustration.png"
                variant="rounded"
                styleProps={{
                    width: '100%',
                    maxWidth: 500,
                    opacity: 0.5,
                    height: 200,
                }}
            />
            <Stack pt={2} spacing={0.5}>
                <Typography
                    sx={{ fontSize: 26, fontWeight: 600, textAlign: 'center' }}>
                    Không tìm thấy bài viết nào phù hợp
                </Typography>
                <Typography sx={{ textAlign: 'center' }}>
                    Hãy thử tìm kiếm với từ khóa khác
                </Typography>
            </Stack>
        </Stack>
    );
}
