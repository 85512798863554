import { Stack, Box, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
    KeyboardDoubleArrowDown as KeyboardDoubleArrowDownIcon,
    MenuBook as MenuBookIcon,
} from '@mui/icons-material';

import { useParams, useLoaderData, useLocation } from 'react-router-dom';
import { useInfiniteQuery, keepPreviousData } from '@tanstack/react-query';
import { searchData } from 'utils/http.js';

import { CustomHelmet, CustomMasonry, CustomButton } from 'UI';
import { LoadingModal } from 'template';

import SectionBreadcrumbs from '../../layout/section/SectionBreadcrumbs.jsx';
import Page404 from '../404/Page404.jsx';

import ArticleCard from './ArticleCard.jsx';
import PageTitle from './subComponents/PageTitle.jsx';
import CategorySection from './subComponents/CategorySection.jsx';
import NoFoundData from './subComponents/NoFoundData.jsx';

import cateLv1Json from 'data/articleLv1.json';
import cateLv2Json from 'data/articleLv2.json';

import PageArticleSkeleton from './skeletons/PageArticleSkeleton.jsx';

let CATEGORIES = [...cateLv1Json, ...cateLv2Json];
const PAGE_SIZE = 12;

export default function PageArticleList({ ...otherProps }) {
    const theme = useTheme();
    const biggerSM = useMediaQuery(theme.breakpoints.up('sm')); // >=600
    let location = useLocation();
    const { subCategoryPath } = useParams();
    const { subCateData } = useLoaderData();

    let subCategoryLevel = subCateData?.level || null;
    let breadcrumbsData = [
        {
            to: `/bai-viet-suc-khoe`,
            name: 'Bài viết sức khỏe',
            disabled: !subCategoryLevel,
        },
    ];

    if (subCategoryLevel === 1) {
        breadcrumbsData.push({
            to: `/bai-viet-suc-khoe/${subCategoryPath}`,
            name: subCateData.name,
            disabled: true,
        });
    } else if (subCategoryLevel === 2) {
        const parentCategory = CATEGORIES.find(
            (item) => item.name === subCateData.parentName
        );
        breadcrumbsData.push(
            {
                to: `/bai-viet-suc-khoe${parentCategory.slug}`,
                name: subCateData.parentName,
            },
            {
                to: `/bai-viet-suc-khoe/${subCategoryPath}`,
                name: subCateData.name,
                disabled: true,
            }
        );
    }
    let subCategories = subCategoryLevel
        ? CATEGORIES.filter(
              (item) => item.level === 2 && item.parentName === subCateData.name
          )
        : CATEGORIES.filter((item) => item.level === 2);
    let cateTitle = subCateData?.name || 'Bài viết sức khỏe';
    const {
        data,
        isLoading, // True only on the initial load.
        isFetching, // True when fetching new data (subsequent requests).
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery({
        queryKey: [
            'article-item',
            {
                subCategoryPath,
            },
        ],
        queryFn: ({ pageParam = 0, signal }) =>
            searchData({
                signal,
                apiUrl: `/article-item?${
                    subCategoryPath ? `subCategoryPath=${subCategoryPath}&` : ''
                }type=1&skip=${pageParam}&take=${PAGE_SIZE}`,
            }),
        getNextPageParam: (lastPage, allPages) => {
            const morePagesExist = lastPage.data.length === PAGE_SIZE;
            if (!morePagesExist) return undefined;
            return allPages.length;
        },
        placeholderData: keepPreviousData,
        staleTime: 30000,
        retry: 0,
    });
    if (subCategoryPath && !subCateData) {
        return <Page404 />;
    }

    if (isLoading) {
        return <PageArticleSkeleton />;
    }
    const mergedArticles = data?.pages?.flatMap((page) => page.data) || [];
    const totalArticles = parseInt(data?.pages?.[0]?.totalCount || 0);

    return (
        <>
            <CustomHelmet
                title={
                    (cateTitle === 'Bài viết sức khỏe'
                        ? cateTitle
                        : 'Các bài viết chủ đề ' + cateTitle.toLowerCase()) +
                    ' - Nhà thuốc Khánh Trang'
                }
                description={
                    'Tổng hợp các bài viết của Nhà thuốc Khánh Trang' +
                    (cateTitle === 'Bài viết sức khỏe'
                        ? ''
                        : ' về ' + cateTitle.toLowerCase()) +
                    ', nhằm cung cấp thông tin sức khỏe, tư vấn dinh dưỡng và cách chăm sóc, phòng tránh bệnh cho gia đình '
                }
                slug={'https://nhathuockhanhtrang.com.vn' + location.pathname}
            />
            <Stack sx={{ maxWidth: 1260, mx: 'auto' }}>
                <LoadingModal isLoading={isFetching} />
                <Stack spacing={1} my={1} px={{ xs: 3, sm: 5 }}>
                    <SectionBreadcrumbs breadcrumbsData={breadcrumbsData} />
                    {subCategoryLevel !== 2 && (
                        <PageTitle cateTitle={cateTitle} />
                    )}
                </Stack>
                <CategorySection
                    subCatgories={subCategories}
                    parentCategories={
                        subCategoryLevel === null
                            ? CATEGORIES.filter((item) => item.level === 1)
                            : []
                    }
                    currentLevel={subCategoryLevel}
                />
                <Box width="100%" pt={2} px={{ xs: 0, sm: 2 }}>
                    {mergedArticles.length > 0 && (
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            mb={1}
                            pl={3}>
                            <MenuBookIcon
                                sx={{ color: 'rgba(0,0,139,0.75)' }}
                            />
                            <Typography
                                component="h1"
                                sx={{ fontSize: 18, fontWeight: 600 }}>
                                Các bài viết liên quan
                            </Typography>
                        </Stack>
                    )}
                    {!isFetching && mergedArticles.length === 0 && (
                        <NoFoundData />
                    )}
                    {mergedArticles.length > 0 && (
                        <Box px={{ xs: 2, sm: 0 }}>
                            <CustomMasonry
                                spacing={biggerSM ? 2 : 0.75}
                                elevation={1}
                                id="article-item-custom-masonry"
                                columns={{ xs: 1, sm: 2, lg: 3, xl: 4 }}
                                items={mergedArticles.map((articleData) => ({
                                    id: articleData._id,
                                    content: (
                                        <ArticleCard
                                            articleData={articleData}
                                        />
                                    ),
                                }))}
                            />
                        </Box>
                    )}
                    {hasNextPage &&
                    Number(totalArticles - mergedArticles.length) > 0 ? (
                        <Stack
                            alignItems="center"
                            justifyContent="center"
                            mb={1}>
                            <CustomButton
                                id="see-more-articles-custom-button"
                                color="disabled"
                                disableEffect
                                onClick={fetchNextPage}
                                startIcon={<KeyboardDoubleArrowDownIcon />}
                                children={
                                    isFetchingNextPage
                                        ? 'Đang tải...'
                                        : `Xem thêm ${Number(
                                              totalArticles -
                                                  mergedArticles.length
                                          ).toLocaleString()} bài viết`
                                }
                            />
                        </Stack>
                    ) : (
                        totalArticles > PAGE_SIZE && (
                            <Stack
                                spacing={1}
                                alignItems="center"
                                justifyContent="center">
                                <Typography sx={{ fontWeight: 500 }}>
                                    Bạn đã xem hết danh sách
                                </Typography>
                            </Stack>
                        )
                    )}
                </Box>
            </Stack>
        </>
    );
}

export const loader = ({ params }) => {
    let { subCategoryPath } = params;
    const foundSubCategory = subCategoryPath
        ? CATEGORIES.find((item) => item.slug === '/' + subCategoryPath)
        : null;
    return { subCateData: foundSubCategory };
};
