export default function LogoBrand({
    onClick = () => {},
    width = '200px',
    height = '80px',
    styleProps = {},
    ...otherProps
}) {
    return (
        <img
            alt="logo-brand"
            src="../assets/images/khanhtranglogo-02.png"
            style={{
                objectFit: 'cover',
                width,
                height,
                overflow: 'clip',
                cursor: 'pointer',
                ...styleProps,
            }}
            onClick={onClick}
        />
    );
}
