import { redirect } from 'react-router-dom';

export function getTokenDuration() {
    const storedExpirationDate = localStorage.getItem('token_expiration');
    const expirationDate = new Date(storedExpirationDate);
    const now = new Date();
    const duration = expirationDate.getTime() - now.getTime();
    return duration;
}

export function getAuthToken() {
    const access_token = localStorage.getItem('access_token');

    if (!access_token) {
        return null;
    }

    const tokenDuration = getTokenDuration();
    if (tokenDuration < 0) {
        return 'EXPIRED';
    }

    return access_token;
}

export function checkAuthLoader() {
    const token = getAuthToken();
    if (!token) {
        return redirect('/');
    }
    return token;
}

export function logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('token_expiration');
    localStorage.removeItem('userId');
}

export function login(data) {
    localStorage.setItem('access_token', data.access_token);
    let expiration = new Date();
    expiration.setDate(expiration.getDate() + 7);
    localStorage.setItem('token_expiration', expiration.toISOString());
    localStorage.setItem('userId', data.userInfo._id);
}
