import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Alert, AlertTitle } from '@mui/material';

/**
 * CustomAlertBlock component that displays an alert with a title and optional close action.
 *
 * @param {Object} props - The props for the component.
 * @param {string} [props.id] - An optional unique identifier for the alert.
 * @param {string | React.ReactNode} [props.title=''] - An optional title for the alert.
 * @param {string | React.ReactNode} [props.children=null] - Required content of the alert.
 * @param {function} [props.onClose=() => {}] - Function to call when the alert is closed.
 * @param {'error' | 'warning' | 'success' | 'info'} [props.severity='error'] - The severity level of the alert.
 * @param {'standard' | 'outlined' | 'filled'} [props.variant='standard'] - The variant style of the alert.
 * @param {React.ReactNode} [props.action] - Optional action element, can replace the default close button.
 * @param {Object} [props.AlertTitleProps={}] - Additional props for the AlertTitle component.
 * @param {Object} [otherProps] - Any other props to pass to the Alert component.
 * @returns {JSX.Element} The rendered alert component.
 */

function CustomAlertBlock({
    id,
    title = '',
    children = null,
    onClose = () => {},
    severity = 'error',
    variant = 'standard',
    action,
    AlertTitleProps = {},
    ...otherProps
}) {
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return; // prevent close alert by clicking outside box
        }

        onClose();
    };

    const alertStyles = {
        width: '100%',
        '& .MuiAlert-message': {
            py: 0.5,
        },
        '& .MuiAlertTitle-root': {
            pb: 1,
            fontSize: 18,
        },
        '& .MuiAlert-action': { pt: 0.25 },
    };
    const alertId = useMemo(
        () => id || Math.random().toString(36).slice(2, 11),
        [id]
    );
    return (
        <Alert
            id={alertId}
            onClose={handleClose}
            color={severity} // overide styles of severity
            severity={severity}
            action={action}
            variant={variant}
            sx={alertStyles}
            {...otherProps}>
            {title && (
                <AlertTitle sx={{ m: 0, p: 0 }} {...AlertTitleProps}>
                    {title}
                </AlertTitle>
            )}
            {children}
        </Alert>
    );
}

CustomAlertBlock.propTypes = {
    id: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
        .isRequired,
    onClose: PropTypes.func.isRequired,
    severity: PropTypes.oneOf(['error', 'warning', 'success', 'info']),
    variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
    action: PropTypes.node,
    AlertTitleProps: PropTypes.object,
};

CustomAlertBlock.defaultProps = {
    id: undefined,
    title: '',
    children: null,
    onClose: () => {},
    severity: 'error',
    variant: 'standard',
    action: undefined,
    AlertTitleProps: {},
};

export default CustomAlertBlock;
