import { configureStore } from '@reduxjs/toolkit';

import userInfoReducer from './userInfo-slice.js';
import searchHistoryReducer from './searchHistory-slice';
import recentWatchProdReducer from './recentWatchProd-slice.js';
import systemReducer from './system-slice.js';

const store = configureStore({
    reducer: {
        userInfo: userInfoReducer, // name 'userInfo' used for useSelector((state) => state.userInfo....)
        searchHistory: searchHistoryReducer,
        recentWatchProd: recentWatchProdReducer,
        system: systemReducer,
    },
});

export default store;
