import { useState, useEffect } from 'react';
import {
    Stack,
    Box,
    Typography,
    useMediaQuery,
    Card,
    CardHeader,
    Divider,
    ListItemButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
    KeyboardDoubleArrowDown as KeyboardDoubleArrowDownIcon,
    FormatListBulleted as FormatListBulletedIcon,
} from '@mui/icons-material';

import moment from 'moment';
import { useNavigate, useParams, useLoaderData } from 'react-router-dom';
import domain from 'config/domain.js';

import {
    CustomHelmet,
    CustomAvatar,
    CustomButton,
    CustomToggleButton,
    CustomList,
} from 'UI';

import SectionBreadcrumbs from '../../layout/section/SectionBreadcrumbs.jsx';
import Page404 from '../404/Page404.jsx';

import PageItemDetails from './PageItemDetails.js';
import RecentWatchProd from './RecentWatchProd.jsx';
import SimilarArticle from './SimilarArticle.jsx';

function BoxDetails({
    title = '',
    modifiedAt = new Date(),
    content = [],
    caution = '',
    keywords = [],
    ...otherProps
}) {
    const navigate = useNavigate();
    const theme = useTheme();
    const biggerMD = useMediaQuery(theme.breakpoints.up('md')); // >= 800
    const [viewBiggerText, setViewBiggerText] = useState(false);
    const [isShowMore, setIsShowMore] = useState(false);
    const handleToggleMoreDetails = () => {
        setIsShowMore((v) => !v);
    };
    let headerContent = content
        .filter((i) => i.type === 'header1' && i.content)
        .map((i) => i.content);
    const [selectedPart, setSelectedPart] = useState(headerContent?.[0] || '');
    const scrollToPart = (content) => {
        const h1Elements = document.querySelectorAll('h1');
        for (let h1 of h1Elements) {
            if (h1.textContent.toLowerCase() === content.toLowerCase()) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                h1.scrollIntoView({ behavior: 'smooth' });
                setTimeout(() => {
                    h1.scrollIntoView({ behavior: 'smooth' });
                    if (!isShowMore) {
                        setSelectedPart(content);
                    }
                }, 100);
                break;
            }
        }
    };
    const scrollToTop = () => {
        const box = document.getElementById('box-content-info');
        if (box) {
            box.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };
    useEffect(() => {
        const handleScroll = () => {
            const h1Elements = document.querySelectorAll('h1');
            let found = false;

            for (let index = 0; index < h1Elements.length; index++) {
                const h1 = h1Elements[index];
                const nextH1 = h1Elements?.[index + 1] || null;

                const currentRect = h1.getBoundingClientRect();
                const offset = 10; // Adjust this value to control how far past the top the h1 needs to be

                // Condition 1: Current h1 is at or slightly past the top of the viewport
                if (currentRect.top <= offset && currentRect.bottom > offset) {
                    setSelectedPart(h1.textContent);
                    found = true;
                    break;
                }

                // Condition 2: Scrolling between this h1 and the next h1
                if (nextH1) {
                    const nextRect = nextH1.getBoundingClientRect();
                    if (currentRect.top < 0 && nextRect.top > offset) {
                        setSelectedPart(h1.textContent);
                        found = true;
                        break;
                    }
                }

                // Condition 3: Final h1 element
                if (!nextH1 && currentRect.top < 0) {
                    setSelectedPart(h1.textContent);
                    found = true;
                    break;
                }
            }

            if (!found) {
                setSelectedPart(headerContent?.[0] || '');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [headerContent]);
    return (
        <Stack
            id="box-content-info"
            sx={{
                backgroundColor: 'white',
                borderRadius: { xs: 0, sm: '8px' },
                mt: 2,
            }}
            spacing={1}>
            {content.length === 1 && content[0].isUpdating ? (
                <Box sx={{ width: '100%' }}>
                    <Card
                        variant="outlined"
                        sx={{ width: '100%', border: 'none' }}>
                        <CardHeader
                            sx={{ px: 3, pb: 0 }}
                            title={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}>
                                    <Typography
                                        component="h1"
                                        variant="h6"
                                        sx={{
                                            fontSize: biggerMD
                                                ? viewBiggerText
                                                    ? 30
                                                    : 28
                                                : viewBiggerText
                                                ? 22
                                                : 18,
                                            fontWeight: 700,
                                            mr: 'auto',
                                        }}>
                                        {title}
                                    </Typography>
                                    <Divider />
                                    <Stack
                                        alignItems="flex-start"
                                        spacing={0.5}
                                        py={1}>
                                        <Typography>
                                            Nội dung chi tiết đang được cập
                                            nhật...
                                        </Typography>
                                        {keywords.length > 0 && (
                                            <div>
                                                <Typography
                                                    sx={{
                                                        fontSize: 14,
                                                        fontWeight: 500,
                                                    }}
                                                    component="span">
                                                    Từ khóa:
                                                </Typography>
                                                {keywords.map(
                                                    (keyword, idx) => {
                                                        return (
                                                            <Typography
                                                                component="span"
                                                                onClick={() => {
                                                                    navigate(
                                                                        '/tim-kiem',
                                                                        {
                                                                            state: {
                                                                                filterBy:
                                                                                    [
                                                                                        'Sản phẩm',
                                                                                    ],
                                                                                searchData:
                                                                                    {
                                                                                        keyword:
                                                                                            keyword.trim(),
                                                                                    },
                                                                            },
                                                                        }
                                                                    );
                                                                }}
                                                                key={
                                                                    'keywords-' +
                                                                    keyword +
                                                                    '-' +
                                                                    idx
                                                                }
                                                                sx={{
                                                                    fontSize: 14,
                                                                    fontStyle:
                                                                        'italic',
                                                                    textDecoration:
                                                                        'none',
                                                                    textTransform:
                                                                        'initial',
                                                                    color: 'blue',
                                                                    px: 0.25,
                                                                    ':hover': {
                                                                        backgroundColor:
                                                                            'transparent',
                                                                        color: 'black',
                                                                        textDecoration:
                                                                            'underline',
                                                                    },
                                                                    cursor: 'pointer',
                                                                }}>
                                                                {keyword.trim()}
                                                                {idx ===
                                                                keywords.length -
                                                                    1
                                                                    ? '.'
                                                                    : ','}
                                                            </Typography>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        )}
                                    </Stack>
                                </Box>
                            }
                        />
                    </Card>
                </Box>
            ) : (
                <>
                    <Stack
                        direction="row"
                        alignItems="stretch"
                        justifyContent="space-between">
                        {headerContent.length > 0 && biggerMD && (
                            <Box p={3} sx={{ minHeight: '100%' }}>
                                <Box
                                    width="250px"
                                    sx={{
                                        border: '1px solid lightgrey',
                                        borderRadius: '12px',
                                        position: '-webkit-sticky',
                                        position: 'sticky',
                                        top: 10,
                                        overflow: 'hidden',
                                    }}>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="flex-start"
                                        spacing={1}
                                        p={1}
                                        pl={2}
                                        sx={{
                                            backgroundColor: '#EDEDED',
                                        }}>
                                        <FormatListBulletedIcon
                                            sx={{ color: 'grey' }}
                                        />
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: 600,
                                                color: 'grey',
                                            }}>
                                            Nội dung chính
                                        </Typography>
                                    </Stack>
                                    <CustomList
                                        id="references-content-listId"
                                        density="compact"
                                        items={headerContent.map(
                                            (content, idx) => ({
                                                name: content + idx,
                                                label: null,
                                                subLabel: (
                                                    <ListItemButton
                                                        sx={{
                                                            py: '8px',
                                                            backgroundColor:
                                                                selectedPart.toLowerCase() ===
                                                                content.toLowerCase()
                                                                    ? 'aliceblue'
                                                                    : 'transparent',
                                                            ':hover': {
                                                                backgroundColor:
                                                                    selectedPart.toLowerCase() ===
                                                                    content.toLowerCase()
                                                                        ? 'aliceblue'
                                                                        : 'transparent',
                                                                color: 'blue',
                                                            },
                                                        }}
                                                        onClick={() =>
                                                            scrollToPart(
                                                                content
                                                            )
                                                        }>
                                                        <Typography
                                                            sx={{
                                                                m: 0,
                                                                fontWeight:
                                                                    selectedPart.toLowerCase() ===
                                                                    content.toLowerCase()
                                                                        ? 600
                                                                        : 500,
                                                                fontSize: 14,
                                                            }}>
                                                            {content}
                                                        </Typography>
                                                    </ListItemButton>
                                                ),
                                            })
                                        )}
                                    />
                                </Box>
                            </Box>
                        )}
                        <Box sx={{ width: '100%' }}>
                            <Card
                                variant="outlined"
                                sx={{ width: '100%', border: 'none' }}>
                                <CardHeader
                                    sx={{ px: 3, pb: 0 }}
                                    title={
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}>
                                            <Typography
                                                component="h1"
                                                variant="h6"
                                                sx={{
                                                    fontSize: biggerMD
                                                        ? viewBiggerText
                                                            ? 30
                                                            : 28
                                                        : viewBiggerText
                                                        ? 22
                                                        : 18,
                                                    fontWeight: 700,
                                                    mr: 'auto',
                                                }}>
                                                {title}
                                            </Typography>
                                            <Divider />
                                            {content.length > 0 && (
                                                <Stack
                                                    direction={{
                                                        xs: 'column',
                                                        lg: 'row',
                                                    }}
                                                    alignItems={{
                                                        xs: 'flex-start',
                                                        lg: 'center',
                                                    }}
                                                    justifyContent={{
                                                        xs: 'flex-start',
                                                        lg: 'space-between',
                                                    }}>
                                                    <Typography
                                                        noWrap
                                                        variant="subtitle1"
                                                        sx={{
                                                            minWidth:
                                                                'fit-content',
                                                            color: 'grey',
                                                            fontWeight: 14,
                                                        }}>
                                                        {`Ngày cập nhật: ${moment(
                                                            modifiedAt
                                                        ).format(
                                                            'DD-MM-YYYY'
                                                        )}`}
                                                    </Typography>
                                                    <Stack
                                                        direction="row"
                                                        justifyContent={{
                                                            xs: 'space-between',
                                                            lg: 'flex-end',
                                                        }}
                                                        width={{
                                                            xs: '100%',
                                                            lg: 'auto',
                                                        }}
                                                        alignItems="center"
                                                        spacing={1}
                                                        mt={0.25}>
                                                        <Typography
                                                            sx={{
                                                                fontSize: 14,
                                                            }}>
                                                            Kích thước chữ
                                                        </Typography>
                                                        <CustomToggleButton
                                                            id="font-size-custom-toggle-button"
                                                            sx={{
                                                                '&.MuiToggleButtonGroup-root':
                                                                    {
                                                                        borderRadius:
                                                                            '20px',
                                                                        alignItems:
                                                                            'center',
                                                                        border: '1px solid lightgrey',
                                                                        p: 0.25,
                                                                    },
                                                                '& .MuiToggleButtonGroup-grouped':
                                                                    {
                                                                        p: '1px',
                                                                        '&.Mui-disabled':
                                                                            {
                                                                                borderRadius: 0,
                                                                            },
                                                                        '&:first-of-type':
                                                                            {
                                                                                borderRadius:
                                                                                    '20px',
                                                                                border: 'none',
                                                                                backgroundColor:
                                                                                    !viewBiggerText
                                                                                        ? 'rgba(0,0,139,0.75)'
                                                                                        : 'transparent',
                                                                                p: 0.25,
                                                                                minWidth: 40,
                                                                                m: 0.1,
                                                                            },
                                                                        '&:not(:first-of-type)':
                                                                            {
                                                                                borderRadius:
                                                                                    '20px',
                                                                                border: 'none',
                                                                                backgroundColor:
                                                                                    viewBiggerText
                                                                                        ? 'rgba(0,0,139,0.75)'
                                                                                        : 'transparent',
                                                                                p: 0.25,
                                                                                minWidth: 40,
                                                                                m: 0.1,
                                                                            },
                                                                    },
                                                            }}
                                                            options={[
                                                                {
                                                                    value: false,
                                                                    icon: (
                                                                        <CustomAvatar
                                                                            variant="rounded"
                                                                            styleProps={{
                                                                                fontSize: 12,
                                                                                width: 60,
                                                                                heigth: 10,
                                                                                backgroundColor:
                                                                                    'transparent',
                                                                                color: !viewBiggerText
                                                                                    ? 'white'
                                                                                    : 'black',
                                                                                textTransform:
                                                                                    'initial',
                                                                            }}>
                                                                            Mặc
                                                                            định
                                                                        </CustomAvatar>
                                                                    ),
                                                                },
                                                                {
                                                                    value: true,
                                                                    icon: (
                                                                        <CustomAvatar
                                                                            variant="rounded"
                                                                            styleProps={{
                                                                                fontSize: 12,
                                                                                width: 60,
                                                                                heigth: 10,
                                                                                backgroundColor:
                                                                                    'transparent',
                                                                                color: viewBiggerText
                                                                                    ? 'white'
                                                                                    : 'black',
                                                                                textTransform:
                                                                                    'initial',
                                                                            }}>
                                                                            Lớn
                                                                            hơn
                                                                        </CustomAvatar>
                                                                    ),
                                                                },
                                                            ]}
                                                            value={
                                                                viewBiggerText
                                                            }
                                                            onChange={() => {
                                                                setViewBiggerText(
                                                                    (
                                                                        prevValue
                                                                    ) =>
                                                                        !prevValue
                                                                );
                                                            }}
                                                            exclusive
                                                            size="small"
                                                        />
                                                    </Stack>
                                                </Stack>
                                            )}
                                        </Box>
                                    }
                                />
                                <Box sx={{ px: { xs: 2, sm: 0 } }}>
                                    <PageItemDetails
                                        newContent={content}
                                        toggleMoreDetails={isShowMore}
                                        keywordArr={keywords}
                                        viewBiggerText={viewBiggerText}
                                    />
                                </Box>
                            </Card>
                            {content.length > 0 && (
                                <Box
                                    sx={{
                                        p: 0,
                                        position: 'relative',
                                    }}>
                                    {isShowMore ? (
                                        <Stack alignItems="center" width="100%">
                                            <CustomButton
                                                id="show-less-details-custom-button"
                                                disableEffect
                                                onClick={() => {
                                                    scrollToTop();
                                                    if (
                                                        headerContent.length > 0
                                                    ) {
                                                        setSelectedPart(
                                                            headerContent[0]
                                                        );
                                                    }
                                                    handleToggleMoreDetails();
                                                }}
                                                fullWidth
                                                styleProps={{
                                                    width: 'fit-content',
                                                    mb: 1,
                                                    fontWeight: 500,
                                                    fontSize: 14,
                                                }}
                                                startIcon={
                                                    <KeyboardDoubleArrowDownIcon
                                                        sx={{
                                                            transform:
                                                                'rotate(180deg)',
                                                        }}
                                                    />
                                                }
                                                children="Thu gọn"
                                            />
                                        </Stack>
                                    ) : (
                                        <CustomButton
                                            id="show-more-details-custom-button"
                                            disableEffect
                                            onClick={() => {
                                                handleToggleMoreDetails();
                                                if (headerContent.length > 0) {
                                                    scrollToPart(selectedPart);
                                                }
                                            }}
                                            fullWidth
                                            styleProps={{
                                                position: 'absolute',
                                                bottom: 0,
                                                background:
                                                    'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 21.88%, rgba(255, 255, 255, 0.95) 45.31%, #fff 67.71%, #fff 100%)',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                height: '76px',
                                                alignItems: 'center',
                                                fontWeight: 500,
                                                fontSize: 14,
                                            }}
                                            startIcon={
                                                <KeyboardDoubleArrowDownIcon />
                                            }
                                            children="Xem thêm"
                                        />
                                    )}
                                </Box>
                            )}
                        </Box>
                    </Stack>
                    {caution && (
                        <Box py={2} px={3}>
                            <Box
                                sx={{
                                    borderLeft: '4px solid rgba(0,0,139,0.75)',
                                    backgroundColor: 'aliceblue',
                                    borderRadius: '4px',
                                    width: '100%',
                                    py: 0.5,
                                    pl: 1,
                                }}>
                                <Typography
                                    variant="caption"
                                    sx={{ color: 'blue', fontWeight: 600 }}>
                                    {caution}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </>
            )}
        </Stack>
    );
}
export default function PageArticleItem({ ...otherProps }) {
    const { itemSlug } = useParams();
    const { itemData } = useLoaderData();
    if (itemSlug && !itemData) {
        return <Page404 />;
    }
    let breadcrumbsData = itemData.category
        .map((i) => ({
            to: '/bai-viet-suc-khoe' + i.slug,
            name: i.name,
        }))
        .concat([
            { to: itemData.slug, name: itemData.webName, disabled: true },
        ]);

    return (
        <>
            <CustomHelmet
                slug={`https://nhathuockhanhtrang.com.vn${itemData.slug}`}
                title={itemData.webName + ' - Nhà thuốc Khánh Trang'}
                description={itemData.description}
                image={itemData.primaryImage.mainSrc}
            />
            <Stack sx={{ maxWidth: 1260, mx: 'auto', px: { xs: 0, sm: 2 } }}>
                <Stack spacing={2.5} my={2.5}>
                    <Box pl={{ xs: 2, sm: 0 }}>
                        <SectionBreadcrumbs breadcrumbsData={breadcrumbsData} />
                    </Box>
                    <BoxDetails
                        content={JSON.parse(itemData.contentInfo)}
                        title={itemData.webName}
                        keywords={itemData.keywords
                            .filter((i) => i.status)
                            .map((i) => i.keyName)}
                        caution={''}
                        modifiedAt={itemData.modifiedAt}
                    />
                    <SimilarArticle
                        keywords={itemData.keywords}
                        currentSlug={itemData.slug}
                    />
                    <RecentWatchProd />
                </Stack>
            </Stack>
        </>
    );
}

export const loader = async ({ params }) => {
    let { itemSlug } = params;
    if (itemSlug) {
        let response = await fetch(
            domain + '/article-item/data?itemSlug=' + itemSlug.replace('/', '')
        );
        if (!response.ok) {
            return { itemData: null };
        }
        let data = await response.json();
        return { itemData: data.data };
    }

    return { itemData: null };
};
