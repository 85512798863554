import { useState, useEffect, useCallback } from 'react';

const useCountdown = (initialMinutes, initialSeconds, isActive) => {
    const [minutes, setMinutes] = useState(initialMinutes);
    const [seconds, setSeconds] = useState(initialSeconds);

    useEffect(() => {
        let myInterval;
        if (isActive) {
            myInterval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                }
                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(myInterval);
                    } else {
                        setMinutes(minutes - 1);
                        setSeconds(59);
                    }
                }
            }, 1000);
        }
        return () => {
            clearInterval(myInterval);
        };
    }, [isActive, minutes, seconds]);
    const resetCountdown = useCallback(() => {
        setMinutes(initialMinutes);
        setSeconds(initialSeconds);
    }, [initialMinutes, initialSeconds]);

    return [minutes, seconds, resetCountdown];
};

export default useCountdown;
