import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Menu, MenuItem } from '@mui/material';

import { OptionRow } from 'template';

/**
 * CustomMenu component renders a menu of items, where each item is displayed
 * as an `OptionRow` within an MUI `MenuItem`. Provides custom styling and density
 * options for menu padding.
 *
 * @param {Object} props - Component properties.
 * @param {string} [props.id] - Optional unique ID for the Menu element. A random ID will be generated if not provided.
 * @param {Array<Object>} [props.items=[]] - Array of item objects to display in the menu. Each object should have:
 *    - `label` (any type): Label text for OptionRow, optional.
 *    - `name` (string, required): Unique name for the item, used as the key.
 *    - `disabled` (boolean, optional): disable the item
 *    - `subLabel` (any type, optional): Additional text shown below the label. Defaults to `name` if not provided.
 *    - `icon` (node, optional): Icon to display in the OptionRow.
 *    - `avatar` (node, optional): Avatar to display in the OptionRow.
 *    - `divider` (boolean, optional, default `false`): If true, adds a divider below the MenuItem.
 * @param {string} [props.alignItems='flex-start'] - Controls alignment of items within each MenuItem.
 * @param {boolean} [props.displayCheckmark=false] - Whether show the checkmark before each option.
 * @param {string} [props.color='primary'] - Color of the checkbox.
 * @param {string} [props.margin='none'] - Sets the vertical margin (padding) for the menu: 'none' (default), 'dense', 'normal'.
 * @param {string} [props.density='standard'] - Controls MenuItem padding: 'compact', 'standard' (default), or 'comfortable'.
 * @param {string} [props.anchorEl=null] - Controll the position of menu.
 * @param {Object} [props.MenuListProps={}] - Additional props for the MUI MenuList component.
 * @param {Object} [props.MenuItemProps={}] - Additional props for the MUI MenuItem component.
 * @param {Function} props.onClose - Callback fired when the menu closes.
 * @param {Function} props.onSelectItem - Callback fired when the select an item.
 * @param {Function} props.getSelectedItem - Function to determine if an item is selected.
 * @returns {JSX.Element} The rendered custom menu.
 */

function CustomMenu({
    id,
    items = [],
    alignItems = 'flex-start',
    displayCheckmark = false,
    color = 'primary',
    margin = 'none',
    density = 'standard',
    anchorEl = null,
    MenuListProps = {},
    MenuItemProps = {},
    onClose = () => {},
    onSelectItem = (item) => {},
    getSelectedItem = (item) => false,
    ...otherProps
}) {
    const menuId = useMemo(
        () => id || Math.random().toString(36).slice(2, 11),
        [id]
    );

    const handleItemClick = useCallback(
        (item) => {
            onSelectItem(item);
            onClose();
        },
        [onSelectItem, onClose]
    );

    return (
        <Menu
            id={menuId}
            open={Boolean(anchorEl)}
            onClose={onClose}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            slotProps={{
                paper: {
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1,
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                },
            }}
            MenuListProps={{
                sx: {
                    py:
                        margin === 'none'
                            ? 0
                            : margin === 'dense'
                            ? '4px'
                            : '8px',
                },
                ...MenuListProps,
            }}
            {...otherProps}>
            {items.map((item, idx) => {
                let {
                    label,
                    name,
                    disabled = false,
                    subLabel = undefined,
                    divider = false,
                    ...props
                } = item;

                return (
                    <MenuItem
                        key={name}
                        disableGutters
                        divider={divider}
                        disabled={disabled}
                        onClick={() => handleItemClick(item)}
                        sx={{
                            '&.MuiMenuItem-root': {
                                py:
                                    density === 'compact'
                                        ? 0
                                        : density === 'standard'
                                        ? '4px'
                                        : density === 'comfortable'
                                        ? '8px'
                                        : 0,
                                pl: 1,
                            },
                        }}
                        {...MenuItemProps}>
                        <OptionRow
                            label={label}
                            subLabel={subLabel !== undefined ? subLabel : name}
                            selected={getSelectedItem(item)}
                            displayCheckmark={displayCheckmark}
                            displayHighlight={false}
                            alignItems={alignItems}
                            {...props}
                        />
                    </MenuItem>
                );
            })}
        </Menu>
    );
}
CustomMenu.propTypes = {
    id: PropTypes.string,
    alignItems: PropTypes.oneOf([
        'flex-start',
        'center',
        'flex-end',
        'stretch',
    ]),
    displayCheckmark: PropTypes.bool,
    color: PropTypes.oneOfType([
        PropTypes.oneOf([
            'primary',
            'secondary',
            'success',
            'error',
            'info',
            'warning',
            'disabled',
        ]),
        PropTypes.string, // For custom colors
    ]),
    density: PropTypes.oneOf(['compact', 'standard', 'comfortable']),
    margin: PropTypes.oneOf(['none', 'dense', 'normal']),
    anchorEl: PropTypes.object.isRequired,
    MenuListProps: PropTypes.object,
    MenuItemProps: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onSelectItem: PropTypes.func,
    getSelectedItem: PropTypes.func,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            name: PropTypes.string.isRequired,
            disabled: PropTypes.bool,
            subLabel: PropTypes.string,
            icon: PropTypes.any,
            avatar: PropTypes.any,
            divider: PropTypes.bool,
        })
    ),
};
CustomMenu.defaultProps = {
    alignItems: 'flex-start',
    displayCheckmark: false,
    color: 'primary',
    density: 'standard',
    margin: 'none',
    anchorEl: null,
    MenuListProps: {},
    MenuItemProps: {},
    onClose: () => {},
    onSelectItem: () => {},
    getSelectedItem: () => false,
    items: [],
};

export default CustomMenu;
