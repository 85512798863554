import { Box } from '@mui/material';

import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

export default function CustomHighlightText({
    label = '',
    keyword = '',
    highlightFontWeight = 600,
    normalFontWeight = 400,
    hightlightColor = 'orangered',
    normalColor = 'black',
    ...otherProps
}) {
    const matches = match(label, keyword, {
        insideWords: true,
    });
    const parts = parse(label, matches);

    return (
        <Box
            component={'div'}
            sx={{
                flexWrap: 'wrap',
            }}>
            {parts.map((part, index) => (
                <span
                    key={'parts-' + part.text + '-' + index}
                    style={{
                        fontWeight: part.highlight
                            ? highlightFontWeight
                            : normalFontWeight,
                        color: part.highlight ? hightlightColor : normalColor,
                    }}>
                    {part.text}
                </span>
            ))}
        </Box>
    );
}
