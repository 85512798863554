import { useEffect } from 'react';

import { useLocation, Outlet } from 'react-router-dom';

import PageLayout from './PageLayout';

export default function ShopMainScreen({ ...otherProps }) {
    const location = useLocation().pathname;
    //auto scroll to top if change screen
    useEffect(() => {
        if (location) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [location]);

    return (
        <PageLayout route="shop">
            <Outlet />
        </PageLayout>
    );
}
