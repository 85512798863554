import { Stack, Box, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CircleRounded as CircleRoundedIcon } from '@mui/icons-material';

import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { searchData } from 'utils/http.js';

import { underlineWithTransition } from 'config/customCSS.js';

import { CustomMasonry } from 'UI';

import ProductCard from '../products/ProductCard';

export default function RecentWatchProd({ ...otherProps }) {
    const theme = useTheme();
    const biggerSM = useMediaQuery(theme.breakpoints.up('sm')); // >=600
    const recentWatchProd = useSelector((state) => state.recentWatchProd);
    const { data } = useQuery({
        queryKey: ['product-item', { recentWatchProd }],
        queryFn: ({ signal }) =>
            searchData({
                signal,
                apiUrl: `/product-item?sku=${recentWatchProd.join(
                    ','
                )}&type=1&skip=0&take=20`,
            }),
        staleTime: 30000,
        retry: 0,
        enabled: recentWatchProd.length > 0,
    });

    if (recentWatchProd.length === 0 || !(data && data.data.length > 0)) {
        return null;
    }
    return (
        <Box pb={2}>
            <Stack pl={2} direction="row" alignItems="center" spacing={1}>
                <CircleRoundedIcon
                    color="primary"
                    sx={{
                        width: 14,
                        height: 14,
                        pt: 0.5,
                        borderRadius: '50%',
                        borderTop: `3px solid ${theme.palette.primary.main}`,
                    }}
                />
                <Typography
                    variant="h6"
                    color="primary"
                    sx={underlineWithTransition}>
                    Sản phẩm vừa xem
                </Typography>
            </Stack>
            <CustomMasonry
                spacing={biggerSM ? 2 : 0.75}
                elevation={1}
                id="recentWatch-custom-masonry"
                columns={{ xs: 2, sm: 3, md: 4, xl: 6 }}
                items={data.data
                    .sort(
                        (a, b) =>
                            recentWatchProd.indexOf(a.sku) -
                            recentWatchProd.indexOf(b.sku)
                    )
                    .map((prodData) => {
                        return {
                            id: prodData._id,
                            content: <ProductCard prodData={prodData} />,
                        };
                    })}
                wrap={false}
            />
        </Box>
    );
}
